import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { searchOrderData, deleteConsiderOrderData, postApplyOrderListData, postConsiderOrderListData } from '@/api/orderInfoEndpoint';

type OrderCard = {
  orderId: number;
  unreadFlg: boolean;
  empTypeName: string;
  ocupctgName: string;
  catchCopy: string;
  salary: string;
  comment: string;
  companyName: string;
  location: string;
  recommendedPointFlextimeFlg: boolean;
  recommendedPointWeekendOffFlg: boolean;
  recommendedPointNoOvertimeFlg: boolean;
  recommendedPointHomeBasedFlg: boolean;
  recommendedPointOvertimeFlg: boolean;
  recommendedPointNoRelocationFlg: boolean;
  considerFlg: boolean;
  orderStatusId: number;
  checked: boolean;
};
type SearchOrderState = {
  indsctgIdForApi: number[] | null;
  ocupctgIdForApi: number[] | null;
  sendSearchOrder: {
    keyword: string | null;
    indsctgId: number[] | null;
    ocupctgId: string[] | null;
    wkprefId: number[] | null;
    emptypeFulltimeemployeeFlg: boolean;
    emptypeContractemployeeFlg: boolean;
    emptypeTemporarystaffFlg: boolean;
    emptypeIntroductiondispatchingFlg: boolean;
    emptypeSubcontractingFlg: boolean;
    salaryType: number | null;
    salaryMin: number | null;
    salaryMax: number | null;
    recommendedPoint1_4DayworkFlg: boolean;
    recommendedPointWeekendOffFlg: boolean;
    recommendedPointSrtWkhourFlg: boolean;
    recommendedPoint1_5HourworkFlg: boolean;
    recommendedPointNoOvertimeFlg: boolean;
    recommendedPointOvertimeFlg: boolean;
    recommendedPointWorkFrom_10Flg: boolean;
    recommendedPointWorkTo_18Flg: boolean;
    recommendedPointFlextimeFlg: boolean;
    recommendedPointSalary_130Flg: boolean;
    recommendedPointHomeBasedFlg: boolean;
    recommendedPointSideWorkFlg: boolean;
    recommendedPointNoRelocationFlg: boolean;
    recommendedPointTransportPaidFlg: boolean;
    recommendedPointChildcareSupportFlg: boolean;
    recommendedPointComHouseSupportFlg: boolean;
    recommendedPointTrainingFlg: boolean;
    recommendedPointStationFrom_5Flg: boolean;
    recommendedPointClothingFreeFlg: boolean;
    recommendedPointHairstyleFreeFlg: boolean;
    recommendedPointListedFlg: boolean;
    recommendedPointForeignComFlg: boolean;
    recommendedPoint2year_10percentFlg: boolean;
    recommendedPointFounded_5yearFlg: boolean;
    recommendedPointMidcareerIn_50Flg: boolean;
    recommendedPointFemale_50percentFlg: boolean;
    recommendedPointFemaleActiveFlg: boolean;
    recommendedPointInexperienceFlg: boolean;
    recommendedPointSemiGradFlg: boolean;
    recommendedPointNoEducationFlg: boolean;
    recommendedPointUseEnglishFlg: boolean;
    recommendedPointUseOtherEnglishFlg: boolean;
    recommendedPointManagerRecruitFlg: boolean;
    recommendedPointUiTurnFlg: boolean;
    recommendedPointShufumamaFlg: boolean;
    recommendedPointExecutiveFlg: boolean;
    orderId: number | null;
    pageNo: number;
    dataNumberOfPages: number;
    sortkeyId: number;
    filterId: number;
  };
  resSearchOrder: {
    dataRecordCountAll: number;
    pageDataCountFrom: number;
    pageDataCountTo: number;
    pageNum: number;
    searchOrderInfo: OrderCard[];
  };
  searchOrderConditions: {
    keyword?: string;
    orderId?: number;
    indsctgIdToTextList?: [string];
    indsctgList?: [string];
    ocupToTextList?: [string];
    ocupList?: [string];
    workAreaTextList?: [string];
    empTypeTextList?: [string];
    recommendedPointTextList?: [string];
    salaryType?: number;
    salaryMin?: number;
    salaryMax?: number;
  };
};
const initIdsForApi = {
  indsctgIdForApi: null,
  ocupctgIdForApi: null,
};
const initSendSearchOrder = {
  keyword: '',
  indsctgId: null,
  ocupctgId: null,
  wkprefId: null,
  emptypeFulltimeemployeeFlg: false,
  emptypeContractemployeeFlg: false,
  emptypeTemporarystaffFlg: false,
  emptypeIntroductiondispatchingFlg: false,
  emptypeSubcontractingFlg: false,
  salaryType: null,
  salaryMin: null,
  salaryMax: null,
  recommendedPoint1_4DayworkFlg: false,
  recommendedPointWeekendOffFlg: false,
  recommendedPointSrtWkhourFlg: false,
  recommendedPoint1_5HourworkFlg: false,
  recommendedPointNoOvertimeFlg: false,
  recommendedPointOvertimeFlg: false,
  recommendedPointWorkFrom_10Flg: false,
  recommendedPointWorkTo_18Flg: false,
  recommendedPointFlextimeFlg: false,
  recommendedPointSalary_130Flg: false,
  recommendedPointHomeBasedFlg: false,
  recommendedPointSideWorkFlg: false,
  recommendedPointNoRelocationFlg: false,
  recommendedPointTransportPaidFlg: false,
  recommendedPointChildcareSupportFlg: false,
  recommendedPointComHouseSupportFlg: false,
  recommendedPointTrainingFlg: false,
  recommendedPointStationFrom_5Flg: false,
  recommendedPointClothingFreeFlg: false,
  recommendedPointHairstyleFreeFlg: false,
  recommendedPointListedFlg: false,
  recommendedPointForeignComFlg: false,
  recommendedPoint2year_10percentFlg: false,
  recommendedPointFounded_5yearFlg: false,
  recommendedPointMidcareerIn_50Flg: false,
  recommendedPointFemale_50percentFlg: false,
  recommendedPointFemaleActiveFlg: false,
  recommendedPointInexperienceFlg: false,
  recommendedPointSemiGradFlg: false,
  recommendedPointNoEducationFlg: false,
  recommendedPointUseEnglishFlg: false,
  recommendedPointUseOtherEnglishFlg: false,
  recommendedPointManagerRecruitFlg: false,
  recommendedPointUiTurnFlg: false,
  recommendedPointShufumamaFlg: false,
  recommendedPointExecutiveFlg: false,
  orderId: null,
  pageNo: 1,
  dataNumberOfPages: 10,
  sortkeyId: 2,
  filterId: 0,
};
const initSearchOrderConditions = {
  keyword: '',
  orderId: undefined,
  indsctgIdToTextList: undefined,
  indsctgList: undefined,
  ocupToTextList: undefined,
  ocupList: undefined,
  workAreaTextList: undefined,
  empTypeTextList: undefined,
  recommendedPointTextList: undefined,
  salaryType: undefined,
  salaryMin: undefined,
  salaryMax: undefined,
};
const searchModule: Module<SearchOrderState, RootState> = {
  namespaced: true,
  state: {
    indsctgIdForApi: [],
    ocupctgIdForApi: [],
    sendSearchOrder: Object.assign({}, initSendSearchOrder),
    resSearchOrder: {
      dataRecordCountAll: 0,
      pageDataCountFrom: 1,
      pageDataCountTo: 1,
      pageNum: 1,
      searchOrderInfo: [],
    },
    searchOrderConditions: Object.assign({}, initSearchOrderConditions),
  },
  getters: {
    search(state) {
      return state;
    },
    sendSearchOrder(state) {
      return state.sendSearchOrder;
    },
    sendSearchOrderForApi(state) {
      return {
        ...state.sendSearchOrder,
        indsctgId: state.indsctgIdForApi,
        ocupctgId: state.ocupctgIdForApi,
      };
    },
    resSearchOrder(state) {
      return state.resSearchOrder;
    },
    searchOrderConditions(state) {
      return state.searchOrderConditions;
    },

    // 検索結果 リスト
    orders(state) {
      return state.resSearchOrder.searchOrderInfo;
    },
    sortKeyId(state) {
      return state.sendSearchOrder.sortkeyId;
    },
    filterId(state) {
      return state.sendSearchOrder.filterId;
    },
    allChecked(state) {
      const hasUnchecked = state.resSearchOrder.searchOrderInfo.find((order) => !order.checked);
      return !hasUnchecked && state.resSearchOrder.dataRecordCountAll > 0;
    },
    hasNextPage(state) {
      return state.resSearchOrder.searchOrderInfo.length < state.resSearchOrder.dataRecordCountAll;
    },
  },

  mutations: {
    initIdsForApi(state) {
      state.indsctgIdForApi = initIdsForApi.indsctgIdForApi;
      state.ocupctgIdForApi = initIdsForApi.ocupctgIdForApi;
    },
    setIdsForApi(state, value) {
      state.indsctgIdForApi = value.indsctgIdForApi;
      state.ocupctgIdForApi = value.ocupctgIdForApi;
    },
    setSendSearchOrder(state, value) {
      Object.assign(state.sendSearchOrder, value);
    },
    setResSearchOrder(state, value: SearchOrderState) {
      Object.assign(state.resSearchOrder, value);
      state.resSearchOrder.searchOrderInfo.map((x) => {
        x.checked = false;
      });
    },
    addResSearchOrder(state, value) {
      value.forEach((item: OrderCard) => {
        item.checked = false;
        state.resSearchOrder.searchOrderInfo.push(item);
      });
    },
    setSearchOrderConditions(state, value) {
      Object.assign(state.searchOrderConditions, value);
    },
    initSearchOrderConditions(state) {
      Object.assign(state.searchOrderConditions, initSearchOrderConditions);
    },
    initSendSearchOrder(state) {
      Object.assign(state.sendSearchOrder, initSendSearchOrder);
    },
    setSortKeyId(state, value: number) {
      state.sendSearchOrder.sortkeyId = value;
    },
    setFilterId(state, value: number) {
      state.sendSearchOrder.filterId = value;
    },
    setPageNumber(state, value: number) {
      state.sendSearchOrder.pageNo = value;
    },
    setConsiderFlg(state, { orderId, value }) {
      const target = state.resSearchOrder.searchOrderInfo.find((order) => order.orderId === orderId);
      if (target) {
        target.considerFlg = value;
      }
    },
    setChecked(state, { orderId, checked }: { orderId: number; checked: boolean }) {
      const target = state.resSearchOrder.searchOrderInfo.find((order) => order.orderId === orderId);
      if (target) {
        target.checked = checked;
      }
    },
    changeAllChecked(state, value: boolean) {
      state.resSearchOrder.searchOrderInfo.forEach((order: OrderCard) => {
        order.checked = value;
      });
    },
    removeOrder(state, { orderId }) {
      const target = state.resSearchOrder.searchOrderInfo.findIndex((order) => order.orderId === orderId);
      state.resSearchOrder.searchOrderInfo.splice(target, 1);
    },
  },

  actions: {
    async receiveSearchOrder({ getters, commit }) {
      commit('setPageNumber', 1);
      const resSearchOrder = await searchOrderData(getters.sendSearchOrderForApi);
      commit('setResSearchOrder', resSearchOrder);
      return resSearchOrder;
    },
    async changeSortKey({ commit, dispatch }, { key }) {
      commit('setSortKeyId', key);
      commit('setPageNumber', 1);
      dispatch('receiveSearchOrder');
    },
    async changeFilter({ commit, dispatch }, { filterId }) {
      commit('setFilterId', filterId);
      commit('setPageNumber', 1);
      dispatch('receiveSearchOrder');
    },
    async applyOrder({ commit }, { orderId }) {
      await postApplyOrderListData({ orderId, revivalApplyFlg: false });
      commit('removeOrder', { orderId });
    },
    // カード個別 検討
    async toggleConsider({ state, commit }, { orderId }) {
      const target = state.resSearchOrder.searchOrderInfo.find((order) => order.orderId === orderId);
      if (target) {
        if (target.considerFlg) {
          await deleteConsiderOrderData({ orderId });
          commit('setConsiderFlg', { orderId, value: false });
        } else {
          await postConsiderOrderListData({ considerPendingOrderList: [{ orderId }] });
          commit('setConsiderFlg', { orderId, value: true });
        }
      }
    },
    // 一括操作 検討
    async addConsider({ state, commit }) {
      const target = state.resSearchOrder.searchOrderInfo.filter((order) => order.checked && !order.considerFlg);
      const orderIds = target.map((e) => {
        return { orderId: e.orderId };
      });
      await postConsiderOrderListData({ considerPendingOrderList: orderIds });
      target.forEach((e) => commit('setConsiderFlg', { orderId: e.orderId, value: true }));
    },
    async moreSee({ getters, commit }) {
      const nextPage = getters.sendSearchOrder.pageNo + 1;
      commit('setPageNumber', nextPage);
      const resSearchOrder = await searchOrderData(getters.sendSearchOrderForApi);
      if (resSearchOrder.searchOrderInfo) {
        commit('addResSearchOrder', resSearchOrder.searchOrderInfo);
      }
    },
  },
};

export default searchModule;
