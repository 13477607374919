import { Module } from 'vuex';
import { RootState } from '@/store/index';
import {
  deleteConsiderOrderData,
  getRecommendOrderListData,
  postApplyOrderListData,
  postConsiderOrderListData,
  postDeclineOrderListData,
} from '@/api/orderInfoEndpoint';
import { OrderCard } from '@/types';

type RecommendOrderState = {
  page: number;
  recordsPerPage: number;
  sortKeyId: number;
  filterId: number;
  total: number;
  orders: OrderCard[];
};

const mnodule: Module<RecommendOrderState, RootState> = {
  namespaced: true,
  state: {
    page: 1,
    recordsPerPage: 10,
    sortKeyId: 4,
    filterId: 0,
    total: 0,
    orders: [],
  },
  getters: {
    hasNextPage(state) {
      return state.page < Math.ceil(state.total / state.recordsPerPage);
    },
    total(state) {
      return state.total;
    },
    sortKeyId(state) {
      return state.sortKeyId;
    },
    filterId(state) {
      return state.filterId;
    },
    orders(state) {
      return state.orders;
    },
    allChecked(state) {
      const ret = state.orders.find((order) => !order.checked);
      return !ret && state.total > 0;
    },
  },

  mutations: {
    setOrders(state, value: RecommendOrderState['orders']) {
      if (value === null) {
        return;
      }
      state.orders.splice(0);
      value.forEach((order) =>
        state.orders.push({
          ...order,
          checked: false,
        })
      );
    },
    addOrders(state, value: RecommendOrderState['orders']) {
      value.forEach((order) =>
        state.orders.push({
          ...order,
          checked: false,
        })
      );
    },
    setPage(state, value: number) {
      state.page = value;
    },
    setTotal(state, value: number) {
      state.total = value;
    },
    setSortKeyId(state, value: number) {
      state.sortKeyId = value;
    },
    setFilterId(state, value: number) {
      state.filterId = value;
    },
    setChecked(state, { orderId, checked }: { orderId: number; checked: boolean }) {
      const target = state.orders.find((order) => order.orderId === orderId);
      if (target) {
        target.checked = checked;
      }
    },
    setConsiderFlg(state, { orderId, value }) {
      const target = state.orders.find((order) => order.orderId === orderId);
      if (target) {
        target.considerFlg = value;
      }
    },
    removeOrder(state, { orderId }) {
      const target = state.orders.findIndex((order) => order.orderId === orderId);
      state.orders.splice(target, 1);
    },
  },

  actions: {
    async receive({ state, commit }) {
      commit('setPage', 1);
      const data = await getRecommendOrderListData(state.page, state.recordsPerPage, state.sortKeyId, state.filterId);
      commit('setOrders', data.recommendOrderInfo);
      commit('setTotal', data.dataRecordCountAll);
    },
    async moreSee({ state, commit }) {
      const nextPage = state.page + 1;
      const data = await getRecommendOrderListData(nextPage, state.recordsPerPage, state.sortKeyId, state.filterId);
      if (data.recommendOrderInfo) {
        commit('addOrders', data.recommendOrderInfo);
        commit('setPage', nextPage);
      }
    },
    async changeSortKey({ commit, dispatch }, { key }) {
      commit('setSortKeyId', key);
      commit('setPage', 1);
      dispatch('receive');
    },
    async changeFilter({ commit, dispatch }, { filterId }) {
      commit('setFilterId', filterId);
      commit('setPage', 1);
      dispatch('receive');
    },
    changeAllChecked({ state, commit }, value: boolean) {
      state.orders.forEach((order) => {
        commit('setChecked', { orderId: order.orderId, checked: value });
      });
    },
    async applyOrder({ commit }, { orderId }) {
      await postApplyOrderListData({ orderId, revivalApplyFlg: false });
      commit('removeOrder', { orderId, applied: true });
    },
    async toggleConsider({ state, commit }, { orderId }) {
      const target = state.orders.find((order) => order.orderId === orderId);
      if (target) {
        if (target.considerFlg) {
          await deleteConsiderOrderData({ orderId });
          commit('setConsiderFlg', { orderId, value: false });
        } else {
          await postConsiderOrderListData({ considerPendingOrderList: [{ orderId }] });
          commit('setConsiderFlg', { orderId, value: true });
        }
      }
    },
    async addConsider({ state, commit }) {
      const target = state.orders.filter((order) => order.checked && !order.considerFlg);
      const orderIds = target.map((e) => {
        return { orderId: e.orderId };
      });
      await postConsiderOrderListData({ considerPendingOrderList: orderIds });
      target.forEach((e) => commit('setConsiderFlg', { orderId: e.orderId, value: true }));
    },
    async declineOrder({ state, commit }) {
      const target = state.orders.filter((order) => order.checked);
      const orderIds = target.map((e) => {
        return { orderId: e.orderId };
      });
      await postDeclineOrderListData({ declineOrderList: orderIds });
      target.forEach((e) => commit('removeOrder', { orderId: e.orderId }));
    },
  },
};

export default mnodule;
