// 求人関連で使用を想定
import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';
import { OfferDetail } from '@/types';
import { useGtm } from '@gtm-support/vue-gtm';

type ApplyOrderListData = {
  orderId: number;
  revivalApplyFlg: boolean;
};
type OrderInfo = {
  orderId: number;
};
type ConsiderOrderListData = {
  considerPendingOrderList: OrderInfo[];
};
type DeclineOrderListData = {
  declineOrderList: OrderInfo[];
};
type SearchOrderConditions = {
  keyword: string | null;
  indsctgId: number[] | null;
  ocupctgId: string[] | null;
  wkprefId: number[] | null;
  emptypeFulltimeemployeeFlg: boolean;
  emptypeContractemployeeFlg: boolean;
  emptypeTemporarystaffFlg: boolean;
  emptypeIntroductiondispatchingFlg: boolean;
  emptypeSubcontractingFlg: boolean;
  salaryType: number | null;
  salaryMin: number | null;
  salaryMax: number | null;
  recommendedPoint1_4DayworkFlg: boolean;
  recommendedPointWeekendOffFlg: boolean;
  recommendedPointSrtWkhourFlg: boolean;
  recommendedPoint1_5HourworkFlg: boolean;
  recommendedPointNoOvertimeFlg: boolean;
  recommendedPointOvertimeFlg: boolean;
  recommendedPointWorkFrom_10Flg: boolean;
  recommendedPointWorkTo_18Flg: boolean;
  recommendedPointFlextimeFlg: boolean;
  recommendedPointSalary_130Flg: boolean;
  recommendedPointHomeBasedFlg: boolean;
  recommendedPointSideWorkFlg: boolean;
  recommendedPointNoRelocationFlg: boolean;
  recommendedPointTransportPaidFlg: boolean;
  recommendedPointChildcareSupportFlg: boolean;
  recommendedPointComHouseSupportFlg: boolean;
  recommendedPointTrainingFlg: boolean;
  recommendedPointStationFrom_5Flg: boolean;
  recommendedPointClothingFreeFlg: boolean;
  recommendedPointHairstyleFreeFlg: boolean;
  recommendedPointListedFlg: boolean;
  recommendedPointForeignComFlg: boolean;
  recommendedPoint2year_10percentFlg: boolean;
  recommendedPointFounded_5yearFlg: boolean;
  recommendedPointMidcareerIn_50Flg: boolean;
  recommendedPointFemale_50percentFlg: boolean;
  recommendedPointFemaleActiveFlg: boolean;
  recommendedPointInexperienceFlg: boolean;
  recommendedPointSemiGradFlg: boolean;
  recommendedPointNoEducationFlg: boolean;
  recommendedPointUseEnglishFlg: boolean;
  recommendedPointUseOtherEnglishFlg: boolean;
  recommendedPointManagerRecruitFlg: boolean;
  recommendedPointUiTurnFlg: boolean;
  recommendedPointShufumamaFlg: boolean;
  recommendedPointExecutiveFlg: boolean;
  orderId: number | null;
  pageNo: number;
  dataNumberOfPages: number;
  sortkeyId: number;
  filterId: number;
};

type RecommendOrderListData = {
  dataRecordCountAll: number;
  pageNum: number;
  pageDataCountFrom: number;
  pageDataCountTo: number;
  recommendOrderInfo: {
    orderId: number;
    unreadFlg: boolean;
    empTypeName: string;
    ocupctgName: string;
    catchCopy: string;
    salary: string;
    comment: string;
    companyName: string;
    location: string;
    recommendedPointFlextimeFlg: boolean;
    recommendedPointWeekendOffFlg: boolean;
    recommendedPointNoOvertimeFlg: boolean;
    recommendedPointHomeBasedFlg: boolean;
    recommendedPointOvertimeFlg: boolean;
    recommendedPointNoRelocationFlg: boolean;
    considerFlg: boolean;
  }[];
};

// 応募中の求人
export const getApplyOrderListData = async () => {
  const res = await axios.get(endpoint.getApplyOrderInfo);
  return res.data;
};
export const postApplyOrderListData = async (params: ApplyOrderListData) => {
  const res = await axios.put(endpoint.addApplyOrderInfo, params);
  const res_careerId = await axios.get(endpoint.getCareerId);

  const gtm = useGtm();
  gtm?.trackEvent({
    event: 'OrderApply',
    category: 'mypage',
    label: '求人応募',
    value: params.orderId,
    'userId01': res_careerId.data.careerId,
  });
  return res;
};
// おすすめの求人
export const getRecommendOrderListData = async (
  pageNo: number,
  dataNumberOfPages: number,
  sortkeyId?: number,
  filterId?: number
): Promise<RecommendOrderListData> => {
  const res = await axios.get(endpoint.getRecommendOrderInfo, {
    params: {
      pageNo: pageNo,
      dataNumberOfPages: dataNumberOfPages,
      sortkeyId: sortkeyId,
      filterId: filterId,
    },
  });
  return res.data;
};
// 検討中の求人
export const getConsiderOrderListData = async (pageNo: number, dataNumberOfPages: number, sortkeyId: number, filterId: number) => {
  const res = await axios.get(
    `${endpoint.getConsiderOrder}?pageNo=${pageNo}&dataNumberOfPages=${dataNumberOfPages}&sortkeyId=${sortkeyId}&filterId=${filterId}`
  );
  return res.data;
};
export const postConsiderOrderListData = async (params: ConsiderOrderListData) => {
  const res = await axios.put(endpoint.addConsiderOrder, params);
  return res;
};
export const deleteConsiderOrderData = async (params: OrderInfo) => {
  const res = await axios.delete(endpoint.deleteConsiderOrder, { data: params });
  return res;
};
// 辞退した求人
export const getDeclineOrderListData = async (pageNo: number, dataNumberOfPages: number, sortkeyId: number, filterId: number) => {
  const res = await axios.get(
    `${endpoint.getDeclineOrder}?pageNo=${pageNo}&dataNumberOfPages=${dataNumberOfPages}&sortkeyId=${sortkeyId}&filterId=${filterId}`
  );
  return res.data;
};
export const postDeclineOrderListData = async (params: DeclineOrderListData) => {
  const res = await axios.put(endpoint.addDeclineOrder, params);
  const gtm = useGtm();

  params.declineOrderList.forEach((order) => {
    gtm?.trackEvent({
      event: 'DeclineApply',
      category: 'mypage',
      label: '求人辞退',
      value: order.orderId,
    });
  });
  return res;
};
// 求人詳細
export const getOrderDetailData = async (params: OrderInfo): Promise<OfferDetail> => {
  const res = await axios.post(endpoint.getOrderDetail, params);
  return res.data;
};
// 求人検索
export const searchOrderData = async (params: SearchOrderConditions) => {
  const res = await axios.post(endpoint.searchOrder, params);
  return res.data;
};
