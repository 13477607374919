import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getUserMenu } from '@/api/accountEndpoint';

type userMenuState = {
  firstname: string;
  lastname: string;
  displayContents: number | null; // 1：キャリアシート未入力、2：面談未予約、3：面談予約済み、4：面談日当日、5：利用規約未確認、6：ホーム画面
  careersheetNonInputStatus: number | null; // 1：連絡先、2：学歴、3：社会人経験、4：社会人経験詳細、6：希望条件、7：言語、8：PCソフト、9：プログラミング言語、10：資格、11：業界経験（5は欠番）
  mainRegTypeId: number | null; // 2:電話 4:web
  mainRegTypeName: string;
  areaName: string;
  cnslDate: string;
  interviewurl: string;
  searchOrderStatus: number | null; // 1:検索期間外（要再同意、キャリアシート確認）、2:作品未提出、3:求人検索可能
  provisionFlg: boolean; // 初回利用規約同意
  searchOrderLockFlg: boolean; // 検索ロック
};

const userMenuModule: Module<userMenuState, RootState> = {
  namespaced: true,
  state: {
    firstname: '',
    lastname: '',
    displayContents: null,
    careersheetNonInputStatus: null, // 1：連絡先、2：学歴、3：社会人経験、4：社会人経験詳細、6：希望条件、7：言語、8：PCソフト、9：プログラミング言語、10：資格、11：業界経験（5は欠番）
    mainRegTypeId: null,
    mainRegTypeName: '',
    areaName: '',
    cnslDate: '',
    interviewurl: '',
    searchOrderStatus: null, // 初回登録時はnull
    provisionFlg: false,
    searchOrderLockFlg: false,
  },
  getters: {
    userMenu(state) {
      return state;
    },
    /** 初回登録時 */
    initialRegistration(state): boolean {
      return state.searchOrderStatus === null;
    },
    /** 検索期間外（要再同意、キャリアシート確認） */
    outsideSearchPeriod(state): boolean {
      return state.searchOrderStatus === 1 || state.searchOrderStatus === null;
    },
    /** 作品未提出 */
    notSubmittedPortfolio(state): boolean {
      return state.searchOrderStatus === 2;
    },
    /** 求人検索可能 */
    searchableOrder(state): boolean {
      return state.searchOrderStatus === 3;
    },
    /** 求人検索ロックユーザー */
    isSearchLockUser(state): boolean {
      return state.searchOrderLockFlg;
    },
  },

  mutations: {
    set(state, value: userMenuState) {
      Object.assign(state, value);
    },
  },

  actions: {
    async receive({ commit }) {
      const userMenu = await getUserMenu();
      commit('set', userMenu);
    },
  },
};

export default userMenuModule;
