const prefix = '/v1/api/massmedian/mypage';
const entryPrefix = '/v1/api/massmedian/mpentry';

export const entryEndpoint = {
  getToken: entryPrefix + '/auth/get-token',
  checkToken: entryPrefix + '/auth/check-token',
  reissueToken: entryPrefix + '/auth/reissue-token',
  master: entryPrefix + '/masters',
  masterDataIndsctg: entryPrefix + '/masters/getMstIndsctg', //業種マスタ
  masterDataOcupClass: entryPrefix + '/masters/getMstOcupClass', //職種マスタ
  userRegistration: entryPrefix + '/user/registration', //転職支援サービス申し込み
  restartAccount: entryPrefix + '/user/restart-account', //運用再開
  restartAccountCheckKey: entryPrefix + '/user/restart-account-check-key', // 運用再開ユニークキー確認
  restartAccountPasswordReset: entryPrefix + '/user/restart-account-password-setting', //運用再開パスワード設定
  checkUserRegistration: entryPrefix + '/user/check-registration', //転職支援サービス申込みチェック
};

export const endpoint = {
  authLogin: prefix + '/auth/login',
  authLogout: prefix + '/auth/logout',

  checkToken: prefix + '/auth/check-token', // get with accessToken
  refreshToken: prefix + '/auth/reissue-token', // post with refreshToken

  passwordReissue: prefix + '/user/password/reissue', // パスワード再発行メール送信
  passwordSetting: prefix + '/user/password/setting', // パスワード再設定
  passwordCheckKey: prefix + '/user/password/check-key', // パスワード再設定 ユニークキー有効チェック

  getCareerId: prefix + '/user/getCareerId', // 求職者ID取得

  getNotice: prefix + '/notice', // お知らせ取得

  userMenu: prefix + '/user/menu', // メニュー表示制御
  updateProvisionAndCareersheet: prefix + '/user/updateProvisionAndCareersheet', // 90日更新
  deactivate: prefix + '/user/deleteServices', // 退会

  updateCareerRegstatus: prefix + '/user/updateRegstatus', // 求職者登録ステータス変更

  provision: prefix + '/provision', // 利用規約

  //マスタデータ取得
  masterDataPref: prefix + '/masters/?targets=pref', //都道府県マスタ
  masterDataSex: prefix + '/masters/?targets=sex', //性別マスタ
  masterDataEmpType: prefix + '/masters/?targets=empType', // 雇用形態マスタ
  masterDataEmpTypeBusicr: prefix + '/masters/?targets=empTypeBusicr', // 雇用形態（職歴用）マスタ
  masterDataEntrance: prefix + '/masters/?targets=entrance', // 入学区分マスタ
  masterDataGrad: prefix + '/masters/?targets=grad', // 卒業区分マスタ
  masterDataWkStatus: prefix + '/masters/?targets=wkStatus', // 現職区分マスタ
  masterDataLangSkl: prefix + '/masters/?targets=langSkl', // 言語マスタ
  masterDataOtrLang: prefix + '/masters/?targets=otrLang', // 外国語マスタ
  masterDataLstSchl: prefix + '/masters/?targets=lstSchl', // 学歴マスタ
  masterDataMedia: prefix + '/masters/?targets=media', // きっかけマスタ
  masterDataIndsctg: prefix + '/masters/getMstIndsctg', //業種マスタ
  masterDataOcupClass: prefix + '/masters/getMstOcupClass', //職種マスタ
  masterDataPostCode: prefix + '/masters/getAddress?zipCode=', //住所検索マスタ
  masterDataMainRegTypeCareer: prefix + '/masters/?targets=mainRegTypeCareer', // 面談形態マスタ

  masterDataSearchConditionWorkArea: prefix + '/masters/getSearchConditionWorkArea', // 求人検索用勤務地情報マスタ

  // キャリアシート入力 C_**
  contactInfo: prefix + '/careersheet/contactInfo', //連絡先 取得 更新
  academicInfo: prefix + '/careersheet/academicInfo', //学歴情報 取得 更新
  adlandexpRegist: prefix + '/careersheet/adlandexpRegist', //社会人経験 取得 更新
  workHistoryInfo: prefix + '/careersheet/workHistoryInfo', // 社会人経験一覧 取得 更新 削除 削除時パラメータ職歴ID busicrId
  workHistoryDetailInfo: prefix + '/careersheet/workHistoryDetailInfo', // 社会人経験詳細 取得 取得時パラメータ職歴ID busicrId
  desiredCondition: prefix + '/careersheet/desiredCondition', // 希望条件 取得 更新
  langSkill: prefix + '/careersheet/langSkill', // 言語 取得 更新
  usableSoftware: prefix + '/careersheet/usableSoftware', // PCソフト 取得 更新
  usableLanguage: prefix + '/careersheet/usableLanguage', // プログラミング言語 取得 更新
  qualificationInfo: prefix + '/careersheet/qualificationInfo', // 資格 取得 更新
  exepInds: prefix + '/careersheet/exepInds', //経験業種 取得 更新

  // D_**画面
  interviewDate: prefix + '/interview/getInterviewDate', // 面談候補日取得
  interviewTime: prefix + '/interview/getInterviewTime', // 面談候補時間取得
  reserveInterview: prefix + '/interview/reserveInterview', // 面談予約
  getInterviewInfo: prefix + '/interview/getInterviewInfo', // 面談情報取得
  cancelInterview: prefix + '/interview/cancelInterview', // 面談情報削除

  // E_**画面, N_**画面
  userBasicInfo: prefix + '/user/basicInfo', // 基本情報 取得 更新
  passwordEdit: prefix + '/user/changePassword', // パスワード 更新
  mailAddress: prefix + '/user/mailAddress', // メールアドレス 取得 更新
  adlandexp: prefix + '/careersheet/adlandexp', // 業界経験 更新

  // F_**画面
  getApplyOrderInfo: prefix + '/apply/getApplyOrderInfo', // 応募中の求人取得
  addApplyOrderInfo: prefix + '/apply/applyOrder', // 応募中の求人取得
  getRecommendOrderInfo: prefix + '/recommend/getRecommendOrderInfo', // おすすめの求人取得
  addConsiderOrder: prefix + '/consider/addConsiderOrder', // 検討中の求人追加
  addDeclineOrder: prefix + '/decline/declineOrder', // 検討中の求人追加

  // I_検討中の求人画面
  getConsiderOrder: prefix + '/consider/getConsiderOrderInfo', // 検討中の求人取得
  deleteConsiderOrder: prefix + '/consider/deleteConsiderOrder', // 検討中の求人取得

  // M_辞退した求人画面
  getDeclineOrder: prefix + '/decline/getDeclineOrderInfo', // 検討中の求人取得

  // G_求人検索
  searchOrder: prefix + '/order/search', // 求人検索

  // H_求人詳細
  getOrderDetail: prefix + '/order/getOrderDetail', // 求人詳細取得
};
