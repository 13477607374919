import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getDesiredCondition, putDesiredCondition, DesiredConditionInfo } from '@/api/careersheetEndpoint';
import { DesiredCondition } from '@/types';

type careerSheetDesiredState = {
  desiredCondition: DesiredCondition;
};

const careerSheetDesiredModule: Module<careerSheetDesiredState, RootState> = {
  namespaced: true,
  state: {
    desiredCondition: {
      demand: '',
      empTypeFlg1: false,
      empTypeFlg2: false,
      empTypeFlg3: false,
      empTypeFlg5: false,
      wishIndsctg1Id: null,
      wishIndsctg2Id: null,
      wishIndsctg3Id: null,
      wishIndsctg4Id: null,
      wishIndsctg5Id: null,
      wishIndsctg6Id: null,
      wishOcupctg1Id: '',
      wishOcupctg2Id: null,
      wishOcupctg3Id: null,
      wishOcupctg4Id: null,
      wishOcupctg5Id: null,
      wishOcupctg6Id: null,
      wishWkarea1Id: null,
      wishWkarea2Id: null,
      wishWkarea3Id: null,
      wishWkarea4Id: null,
      careersheetUpdateFlg: false,
      csEntryCompleteFlg: false,
    },
  },
  getters: {
    desiredCondition(state) {
      return state.desiredCondition;
    },
  },

  mutations: {
    setDesiredCondition(state, value) {
      Object.assign(state.desiredCondition, value);
    },
  },
  actions: {
    async getDesiredCondition({ commit }) {
      try {
        const res = await getDesiredCondition();
        commit('setDesiredCondition', res);
      } catch (e) {
        console.error(e);
      }
    },
    async putDesiredCondition({ getters }) {
      try {
        const param = getters.desiredCondition as DesiredConditionInfo;
        await putDesiredCondition(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetDesiredModule;
