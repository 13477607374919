import { Module } from 'vuex';

let toastId = 0;
export class Toast {
  readonly toastId: number;
  label = '';
  duration = 3000;

  constructor(params: Partial<Toast>) {
    this.toastId = toastId++;
    Object.assign(this, params);
  }
}

const toatsModule: Module<{ toasts: Partial<Toast>[] }, object> = {
  namespaced: true,
  state: {
    toasts: [],
  },
  getters: {
    toasts(state) {
      return state.toasts;
    },
  },
  mutations: {
    appendToast(state, toast: Partial<Toast>) {
      // 同じラベルは表示させない
      if (state.toasts.length === 0 || state.toasts.some((i) => i.label !== toast.label)) {
        state.toasts.unshift(toast);
      }
    },
    removeToast(state, toastId: number) {
      const index = state.toasts.findIndex((toast) => toast.toastId === toastId);
      if (index >= 0) {
        state.toasts.splice(index, 1);
      }
    },
  },
  actions: {
    appendTimeToast(context, params: Partial<Toast>) {
      const toast = new Toast(params);
      context.commit('appendToast', toast);
      if (toast.duration > 0 && isFinite(toast.duration)) {
        setTimeout(() => {
          context.commit('removeToast', toast.toastId);
        }, toast.duration);
      }
    },
  },
};

export default toatsModule;
