import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getProvision, putProvision } from '@/api/provisionEndpoint';

type ProvisionState = {
  consentFlg1?: boolean;
  consentFlg2?: boolean;
  consentFlg3?: boolean;
  consentFlg4?: boolean;
  lastChargeFlg?: boolean;
  aboutDateInterview?: string;
  aboutCommunicationMedia?: string;
};

const provisionModule: Module<ProvisionState, RootState> = {
  namespaced: true,
  state: {
    consentFlg1: undefined,
    consentFlg2: undefined,
    consentFlg3: undefined,
    consentFlg4: undefined,
    lastChargeFlg: undefined,
    aboutDateInterview: undefined,
    aboutCommunicationMedia: undefined,
  },
  getters: {
    provision(state) {
      return state;
    },
  },

  mutations: {
    set(state, value: ProvisionState) {
      Object.assign(state, value);
    },
  },

  actions: {
    async receive({ commit }) {
      const provision = await getProvision();
      commit('set', provision);
    },
    async receiveOnlyOptional({ commit }) {
      const provision = await getProvision();
      commit('set', {
        consentFlg1: undefined,
        consentFlg2: undefined,
        consentFlg3: undefined,
        consentFlg4: undefined,
        lastChargeFlg: undefined,
        aboutDateInterview: provision.aboutDateInterview ? provision.aboutDateInterview : '',
        aboutCommunicationMedia: provision.aboutCommunicationMedia ? provision.aboutCommunicationMedia : '',
      });
    },
    async send({ state }) {
      await putProvision(state);
    },
  },
};

export default provisionModule;
