const VALIDATON_ERRORS = {
  REQUIRED: '${label}は必須項目です',
  NUMERIC: '${label}は半角数字で入力してください',
  EMAIL: 'メールアドレスの形式が正しくありません',
  MAX_LENGTH: '${label}は${max}文字以内で入力してください',
  MIN_LENGTH: '${label}は${min}文字以上入力してください',
  PASSWORD_RULE: 'アルファベットと数字を1文字以上使用してください',
  PASSWORD_CONFIRM: '入力されたパスワードが一致しません',
  PASSWORD_SAME: '現在のパスワードと異なるパスワードを入力してください',
  ZEN_KANA: '全角カナで入力してください',
  ZIPCODE_LENGTH: '郵便番号は7桁の半角数字を入力してください',
  YM: '${label}の入力に不備があります',
  HAN_EISUU_KIGOU: '半角英数記号で入力してください',
};
export default VALIDATON_ERRORS;
