import { noAuthAxios } from '@/plugins/axios';
import { entryEndpoint } from '@/api/apiConstants';

type Csrf = {
  csrfToken: string;
  csrfRefreshToken: string;
};

export const getToken = async (): Promise<Csrf> => {
  const res = await noAuthAxios.get(entryEndpoint.getToken);
  return res.data;
};

export const checkToken = async (csrfToken: string): Promise<void> => {
  await noAuthAxios.post(entryEndpoint.checkToken, { csrfToken });
};

export const refreshToken = async (csrfRefreshToken: string): Promise<Csrf> => {
  const res = await noAuthAxios.post(entryEndpoint.reissueToken, { csrfRefreshToken });
  return res.data;
};
