type Rules = {
  required?: true;
  requiredAndTrue?: true;
  requiredBoolean?: true;
  min?: number;
  max?: number;
  betweenValue?: [number, number];
  numeric?: true;
  email?: true;
  password?: true;
  confirmation?: string;
  not_same?: string;
  zen_kana?: true;
  zipcode_length?: true;
  ym?: true;
  han_eisuu_kigou?: true;
};
export const validationRuleHelper = (rules: Rules) => {
  return rules;
};
