import { noAuthAxios } from '@/plugins/axios';
import { entryEndpoint } from '@/api/apiConstants';

type RestartAccount = {
  firstNameKana: string;
  lastNameKana: string;
  birthDate: string;
  mailAddress: string;
};

// 運用再開手続き
export const putRestartAccount = async (params: RestartAccount, csrfToken: string) => {
  const res = await noAuthAxios.put(entryEndpoint.restartAccount, params, {
    headers: { 'X-CSRF-TOKEN': csrfToken },
  });
  return res.data;
};

// 運用再開ユニークキー確認
export const putRestartAccountCheckKey = async (restartAccountUniqueKey: string) => {
  const res = await noAuthAxios.put(entryEndpoint.restartAccountCheckKey, { restartAccountUniqueKey });
  return res.data;
};

type RestartAccountPasswordReset = {
  restartAccountUniqueKey: string;
  password: string;
};

// 運用再開パスワード登録
export const putRestartAccountPasswordSetting = async (params: RestartAccountPasswordReset) => {
  const res = await noAuthAxios.put(entryEndpoint.restartAccountPasswordReset, params);
  return res.data;
};
