import { onMounted, onUnmounted } from 'vue';
// リロード時 アラート表示
export default function () {
  onMounted(() => {
    window.addEventListener('beforeunload', unloaded, false);
  });
  onUnmounted(() => {
    window.removeEventListener('beforeunload', unloaded, false);
  });

  const unloaded = function (e: Event) {
    e.returnValue = true;
    return '';
  };
}
