import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { ExepInds, getExepInds, putExepInds } from '@/api/careersheetEndpoint';

type careerSheetBusinesstypeState = {
  businesstypeList: { label: string; value: boolean | string; key: string }[];
};

const careerSheetBusinesstypeModule: Module<careerSheetBusinesstypeState, RootState> = {
  namespaced: true,
  state: {
    businesstypeList: [],
  },
  getters: {
    businesstypeList(state) {
      return state.businesstypeList;
    },
    businesstypeListParam(state): { [key: string]: boolean | string | null } {
      const value = Object.fromEntries(
        state.businesstypeList.map((i) => {
          return [i.key, i.value];
        })
      );
      return {
        ...value,
        otherFieldMemo: value.otherFieldFlg ? value.otherFieldMemo : null,
      };
    },
    businesstypeLabelList(state) {
      return state.businesstypeList
        .filter((i) => {
          return i.value && i.key !== 'otherFieldFlg';
        })
        .map((j) => {
          if (j.key === 'otherFieldMemo') {
            return j.value;
          }
          return j.label;
        });
    },
  },

  mutations: {
    setBusinesstypeList(state, value) {
      state.businesstypeList = value;
    },
  },
  actions: {
    async getBusinesstypeList({ commit }) {
      try {
        const res = await getExepInds();
        const value = [
          { label: 'ファッション', value: res.fashionFlg || false, key: 'fashionFlg' },
          { label: '食品・飲料・酒類', value: res.foodFieldFlg || false, key: 'foodFieldFlg' },
          { label: '不動産・住宅・設備', value: res.realEstateFieldFlg || false, key: 'realEstateFieldFlg' },
          { label: '家庭用品・雑品', value: res.articlesFieldFlg || false, key: 'articlesFieldFlg' },
          { label: '化粧品・トイレタリー', value: res.cosmeticsToiletryFieldFlg || false, key: 'cosmeticsToiletryFieldFlg' },
          { label: '医療品・医療用品', value: res.medicalFieldFlg || false, key: 'medicalFieldFlg' },
          { label: '自動車・関連用品', value: res.automobileFieldFlg || false, key: 'automobileFieldFlg' },
          { label: 'IT（通信・情報）関連', value: res.itFieldFlg || false, key: 'itFieldFlg' },
          { label: '家電・AV機器', value: res.electronicsFieldFlg || false, key: 'electronicsFieldFlg' },
          {
            label: '精密機器・事務用品',
            value: res.precisionEquipmentOfficeSuppliesFlg || false,
            key: 'precisionEquipmentOfficeSuppliesFlg',
          },
          { label: '流通・小売業', value: res.retailFieldFlg || false, key: 'retailFieldFlg' },
          { label: '金融・保険・証券', value: res.financingInsuranceSecuritiesFlg || false, key: 'financingInsuranceSecuritiesFlg' },
          { label: '趣味・スポーツ関連', value: res.sportsFieldFlg || false, key: 'sportsFieldFlg' },
          { label: '外食・各種サービス', value: res.foodServiceFieldFlg || false, key: 'foodServiceFieldFlg' },
          { label: '美容・健康', value: res.beautyHealthFieldFlg || false, key: 'beautyHealthFieldFlg' },
          { label: 'エンターテイメント', value: res.entertainmentFieldFlg || false, key: 'entertainmentFieldFlg' },
          {
            label: '運輸・観光・娯楽',
            value: res.transportationSightseeingAmusementFlg || false,
            key: 'transportationSightseeingAmusementFlg',
          },
          { label: '出版', value: res.publicationFieldFlg || false, key: 'publicationFieldFlg' },
          { label: '学校・教育関連', value: res.schoolEducationFieldFlg || false, key: 'schoolEducationFieldFlg' },
          { label: '通信販売・訪問販売', value: res.mailOrderFieldFlg || false, key: 'mailOrderFieldFlg' },
          { label: '官公庁・団体', value: res.governmentAdministrationFieldFlg || false, key: 'governmentAdministrationFieldFlg' },
          { label: 'その他', value: res.otherFieldFlg || false, key: 'otherFieldFlg' },
          { label: 'その他コメント', value: res.otherFieldMemo, key: 'otherFieldMemo' },
        ];
        commit('setBusinesstypeList', value);
      } catch (e) {
        console.error(e);
      }
    },
    async saveBusinesstypeList({ getters }) {
      try {
        const param = getters.businesstypeListParam as ExepInds;
        await putExepInds(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetBusinesstypeModule;
