// 面談場所
const APPOINTMENT_PLACE = [
  { value: '1', label: '東京' },
  { value: '2', label: '大阪' },
  { value: '3', label: '名古屋' },
  { value: '4', label: '福岡' },
  { value: '5', label: '金沢' },
];

export default APPOINTMENT_PLACE;
