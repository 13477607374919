import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getUsableLanguage, putUsableLanguage, UsableLanguage } from '@/api/careersheetEndpoint';

type careerSheetPclanguageState = {
  usableLanguageList: { label: string; value: boolean; key: string }[];
};

const careerSheetPclanguageModule: Module<careerSheetPclanguageState, RootState> = {
  namespaced: true,
  state: {
    usableLanguageList: [],
  },
  getters: {
    usableLanguageList(state) {
      return state.usableLanguageList;
    },
    usableLanguageListParam(state): { [key: string]: boolean } {
      return Object.fromEntries(
        state.usableLanguageList.map((i) => {
          return [i.key, i.value];
        })
      );
    },
  },

  mutations: {
    setUsableLanguageList(state, value) {
      state.usableLanguageList = value.map((i: { label: string; value: boolean; key: string }) => {
        return {
          ...i,
          value: i.value || false,
        };
      });
    },
  },
  actions: {
    async getUsableLanguageList({ commit }) {
      try {
        const res = await getUsableLanguage();
        const value = [
          { label: 'C', value: res.langCFlg, key: 'langCFlg' },
          { label: 'C++', value: res.cplusplusFlg, key: 'cplusplusFlg' },
          { label: 'C#', value: res.csharpFlg, key: 'csharpFlg' },
          { label: 'Java', value: res.javaFlg, key: 'javaFlg' },
          { label: 'PHP', value: res.phpFlg, key: 'phpFlg' },
          { label: 'SQL', value: res.sqlFlg, key: 'sqlFlg' },
          { label: 'ASP', value: res.aspFlg, key: 'aspFlg' },
          { label: 'Javascript', value: res.javascriptFlg, key: 'javascriptFlg' },
          { label: 'HTML', value: res.htmlFlg, key: 'htmlFlg' },
          { label: 'XML', value: res.xmlFlg, key: 'xmlFlg' },
          { label: 'XHTML', value: res.xhtmlFlg, key: 'xhtmlFlg' },
          { label: 'CSS', value: res.cssFlg, key: 'cssFlg' },
          { label: 'Sass', value: res.sassFlg, key: 'sassFlg' },
          { label: 'ftp', value: res.ftpFlg, key: 'ftpFlg' },
          { label: 'WordPress実装', value: res.wordPressFlg, key: 'wordPressFlg' },
          { label: 'Movable Type実装', value: res.movableTypeFlg, key: 'movableTypeFlg' },
          { label: 'R', value: res.langRFlg, key: 'langRFlg' },
          { label: 'Perl', value: res.perlFlg, key: 'perlFlg' },
          { label: 'Ruby', value: res.rubyFlg, key: 'rubyFlg' },
          { label: 'Go', value: res.goFlg, key: 'goFlg' },
          { label: 'Python', value: res.pythonFlg, key: 'pythonFlg' },
          { label: 'Swift', value: res.swiftFlg, key: 'swiftFlg' },
          { label: 'Kotlin', value: res.kotlinFlg, key: 'kotlinFlg' },
        ];
        commit('setUsableLanguageList', value);
      } catch (e) {
        console.error(e);
      }
    },
    async saveUsableLanguageList({ getters }) {
      try {
        const param = getters.usableLanguageListParam as UsableLanguage;
        await putUsableLanguage(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetPclanguageModule;
