<template>
  <main class="is-nav">
    <div class="search_contents_wrapper">
      <img src="@/assets/img/img_search_disable_career.svg" alt="" />

      <div class="search_title">
        求人検索のご利用には
        <br />
        履歴書・職務経歴書のご提出が必要です
      </div>

      <div class="search_overview">
        <div class="search_overview_title">マスメディアンの求人検索</div>
        <div class="search_overview_list">
          <div class="search_overview_list_item">
            マスメディアン保有の
            <span class="search_overview_list_item_highlight">3000件以上</span>
            の求人
          </div>
          <div class="search_overview_list_item">ホームページ掲載の求人</div>
          <div class="search_overview_list_item">企業名も含めた詳細な採用情報</div>
          <div class="search_overview_list_item">
            ボタンクリックで
            <span class="search_overview_list_item_highlight">かんたん応募</span>
          </div>
        </div>
      </div>

      <!-- 必要な書類について -->
      <div class="search_description">
        <div class="search_description_title">必要な書類について</div>
        <div class="search_description_list">
          <div class="search_description_list_item">
            <span class="search_description_list_item_highlight">履歴書</span>
            （顔写真添付）
          </div>
          <div class="search_description_list_item"><span class="search_description_list_item_highlight">職務経歴書</span></div>
          <div class="search_description_list_item">
            <span class="search_description_list_item_highlight">作品集</span>
            （クリエイターの方。上限30枚）
          </div>
        </div>

        <div class="search_description_text">
          求人検索の利用をご希望の方は、
          <span class="search_description_text_highlight">「履歴書」「職務経歴書」「作品集（クリエイターの方）」</span>
          をマスメディアンへご提出してください。
        </div>

        <div class="search_description_text">
          書類をご提出いただいてから求人検索のご利用開始まで、2～5日（営業日換算）かかる場合があります。 予めご了承ください。
        </div>
      </div>

      <!-- 提出方法 -->
      <div class="search_description">
        <div class="search_description_title">提出方法</div>
        <div class="search_description_text">書類は以下のメールアドレスへお送りください。</div>

        <div class="search_description_mail">
          <div class="search_description_mail_title">マスメディアン紹介事務局</div>
          <a href="mailto:webmaster@massmedian.co.jp" class="search_description_mail_link text_link" target="_blank">
            webmaster@massmedian.co.jp
          </a>
        </div>

        <div class="search_description_note">
          <div class="search_description_note_text">ご本人確認のため、「氏名」「生年月日」をお書きください。</div>
          <div class="search_description_note_text">作品集は表紙を含めて30ページ以内でお願いします。</div>
          <div class="search_description_note_text">
            メールでのデータ容量は2MBまででお願いします。容量が大きい場合は大容量データ転送サービスをご利用ください。
          </div>
        </div>
      </div>

      <!-- マスメディアン経由で求人企業へ応募すると -->
      <div class="search_description">
        <div class="search_description_title">マスメディアン経由で求人企業へ応募すると</div>
        <div class="search_description_apply_list">
          <div class="search_description_apply_list_item">
            求人企業への応募書類の提出は、マスメディアンが代行するため、印刷や郵送の手間も省けます。
          </div>
          <div class="search_description_apply_list_item">
            マスメディアンへ提出いただいた書類から、複数の求人企業へ応募が可能です。 書類作成の手間が省けて効率的です。
          </div>
        </div>
        <div class="search_description_text">
          <span class="search_description_text_highlight">
            応募企業に提出する書類について、詳しくは
            <a
              class="text_link search_description_apply_link"
              href="https://www.massmedian.co.jp/service/flow/documents.html"
              target="_blank"
            >
              こちら
            </a>
            をご確認ください。
          </span>
        </div>
      </div>
    </div>
    <!-- END home_contents_wrapper -->
  </main>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
// ーーーーーーーーーー
// mainの幅指定
// ーーーーーーーーーー
main {
  width: 1060px;
  margin: 0 auto;
  padding: 60px 20px;

  // SPの時
  @media screen and (max-width: variables.$breakpoint) {
    width: 100%;
  }

  // ナビゲーションがある時
  &.is-nav {
    // ヘッダー高さ：60px + ナビゲーション高さ：58px
    padding: calc(60px + 58px) 20px 60px;

    // SPの時
    @media screen and (max-width: variables.$breakpoint) {
      // スティッキーナビゲーション高さ：72px
      padding: 60px 20px calc(60px + 72px);
    }
  }
}

// ーーーーーーーーーー
// 検索を使わせない時の表示
// ーーーーーーーーーー
.search_contents_wrapper {
  width: 600px;
  margin: 70px auto 0;
  text-align: center;

  // SPの時
  @media screen and (max-width: variables.$breakpoint) {
    width: 100%;
  }
}

// 概要箇所
// ーーーーーーーーーー

.search_title {
  font-size: variables.$font_large;
  font-weight: bold;
  margin-top: 32px;
}

.search_overview {
  background-color: variables.$color_base;
  border-radius: 8px;
  margin-top: 42px;
  position: relative;
  padding: 40px 0 28px;
  display: flex;
  justify-content: center;
}

.search_overview_title {
  color: variables.$color_primary;
  font-size: variables.$font_large;
  font-weight: bold;
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
}

.search_overview_list {
  text-align: left;
}

.search_overview_list_item {
  font-size: variables.$font_medium;
  margin-top: 4px;

  &::before {
    content: '・';
  }
}

.search_overview_list_item_highlight {
  font-weight: bold;
}

// 各説明書き箇所
// ーーーーーーーーーー
.search_description {
  margin-top: 64px;
}

.search_description_title {
  color: variables.$color_primary;
  font-size: variables.$font_medium;
  padding: 12px 0;
  border-top: 1px solid variables.$color_primary;
  border-bottom: 1px solid variables.$color_primary;
}

.search_description_list {
  text-align: left;
  margin-top: 28px;
}

.search_description_list_item {
  font-size: variables.$font_small;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    background-color: variables.$color_primary;
    border-radius: 50%;
    opacity: 0.5;
    margin-right: 8px;
  }
}

.search_description_list_item + .search_description_list_item {
  margin-top: 4px;
}

.search_description_list_item_highlight {
  font-size: variables.$font_medium;
  font-weight: bold;
  margin-right: 8px;
}

.search_description_text {
  margin-top: 24px;
  text-align: left;
}

.search_description_text_highlight {
  font-weight: bold;
}

// メール箇所
.search_description_mail {
  background-color: variables.$color_base;
  padding: 20px 0;
  border-radius: 8px;
  margin-top: 28px;
  font-weight: bold;
}

.search_description_mail_link {
  display: inline-block;
  margin-top: 4px;
}

.search_description_note {
  text-align: left;
  margin-top: 24px;
}

.search_description_note_text {
  margin-top: 8px;
  padding-left: 16px;
  position: relative;

  &::before {
    content: '※';
    position: absolute;
    top: 0;
    left: 0;
  }
}

.search_description_apply_list {
  text-align: left;
  margin-top: 24px;
}

.search_description_apply_list_item {
  margin-top: 8px;
  padding-left: 16px;
  position: relative;

  &::before {
    content: '●';
    position: absolute;
    top: 0;
    left: 0;
  }
}

.search_description_apply_link {
  text-decoration: underline;
}
</style>
