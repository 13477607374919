import { format, startOfMonth, isAfter, subYears, endOfMonth } from 'date-fns';

/**
 * 今日
 */
export const today = new Date();

/**
 * Date型に変換する
 * @param {number} y 年
 * @param {number} m 月
 * @param {number} d 日
 * @return Date型の日付
 **/
export const numberToDate = (y = 2010, m = 1, d = 1): Date => {
  const yString = String(y);
  const mString = String(m).padStart(2, '0');
  const dString = String(d).padStart(2, '0');
  const ymd = `${yString}-${mString}-${dString}`;
  return new Date(ymd);
};

/**
 * Date型に変換する
 * @param str 日付の文字列(yyyy-MM-dd)
 * @return Date型の日付
 **/
export const stringToDate = (str: string): Date => {
  if (typeof str !== 'string' || str === '') return new Date();
  if (str.length !== 10 && str.length !== 25) {
    console.error('fnc stringToDateの引数の形式はyyyy-MM-dd、又はyyyy-MM-ddT00:00:00+09:00で指定', str);
  }
  return new Date(str);
};

/**
 * typeの形式にdateを変換する
 * @param date
 * @param type yyyy-MM-ddなど
 **/
export const datefnsFormat = (date: Date, type: string): string => {
  return format(date, type);
};

/**
 * yyyy年M月の形式に変換する
 */
export const ymFormat = (date: Date) => {
  return datefnsFormat(date, 'yyyy年M月');
};

/**
 * yyyy年M月d日の形式に変換する
 */
export const ymdFormat = (date: Date) => {
  return datefnsFormat(date, 'yyyy年M月d日');
};

/**
 * yyyy-MM-ddの形式に変換する
 */
export const ymdHyphenFormat = (date: Date) => {
  return datefnsFormat(date, 'yyyy-MM-dd');
};

/**
 * yyyy-MMの形式に変換する
 */
export const ymHyphenFormat = (date: Date) => {
  return datefnsFormat(date, 'yyyy-MM');
};

/**
 * selectedDateがstartDayよりも未来の月か判定
 */
export const isNextMonth = (selectedDate: string, startDay: string) => {
  return isAfter(startOfMonth(stringToDate(selectedDate)), startOfMonth(stringToDate(startDay)));
};

/**
 * 今年(yyyy)
 */
export const thisYear = () => {
  return datefnsFormat(today, 'yyyy');
};

/**
 * 15年前の年の最後の日(yyyy-12-31)
 */
export const fifteenYearsAgo = () => {
  return datefnsFormat(subYears(today, 15), 'yyyy');
};

/**
 * 月末の日
 */
export const lastDayOfMonth = (date: Date) => {
  return format(endOfMonth(date), 'dd');
};
