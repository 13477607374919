import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';

export type Pref = {
  code: number;
  detail: null;
  name: string;
};

export type MasterDataOcupClass = {
  ocup1ClassId: string;
  ocup1ClassName: string;
  mstOcup2ClassDataList: {
    ocup2ClassId: string;
    ocup2ClassName: string;
    mstOcup3ClassDataList: {
      ocup3ClassId: string;
      ocup3ClassName: string;
    }[];
  }[];
}[];

export type MasterDataIndsctg = {
  indsctgLargeId: number;
  indsctgLargeName: string;
  mstIndsctgSmallDataList: {
    indsctgSmallId: number;
    indsctgSmallName: string;
  }[];
}[];

export type MasterDataMainRegTypeCareer = {
  code: number;
  name: string;
  detail: string | null;
};

export type MasterDataLstSchl = {
  code: number;
  name: string;
  detail: string | null;
};

export type MasterDataEntrance = {
  code: number;
  name: string;
  detail: string | null;
};

export type MasterDataGrad = {
  code: number;
  name: string;
  detail: string | null;
};

export type MasterDataLangSkl = {
  code: number;
  name: string;
  detail: string;
};

export type MasterDataOtrLang = {
  code: number;
  name: string;
  detail: null;
};

export type MasterDataSex = {
  code: number;
  name: string;
  detail: null | string;
};

export type MasterDataWkStatus = {
  code: number;
  name: string;
  detail: null | string;
};

export type MasterEmpTypeBusicr = {
  code: number;
  name: string;
  detail: null | string;
};

export type MasterEmpType = {
  code: number;
  name: string;
  detail: null | string;
};

export type MasterDataSearchConditionWorkArea = {
  areaName: string;
  searchConditionWorkPrefDataList: {
    predId: number;
    prefName: string;
  }[];
};

export type PostCodeList = {
  zipId: number;
  zipCode: string;
  prefId: number;
  prefName: string;
  prefKanaName: string;
  cityName: string;
  cityKanaName: string;
  blockName: string;
  blockKanaName: string;
}[];

/** 性別マスタ */
export const getMasterDataListSex = async (): Promise<MasterDataSex[]> => {
  const res = await axios.get(endpoint.masterDataSex);
  return res.data.masterData.sex;
};
export const getMasterDataListMedia = async () => {
  const res = await axios.get(endpoint.masterDataMedia);
  return res.data.masterData.media;
};
export const getMasterDataListEmpType = async (): Promise<MasterEmpType[]> => {
  const res = await axios.get(endpoint.masterDataEmpType);
  return res.data.masterData.empType;
};
/** 雇用形態(職歴) */
export const getMasterDataListEmpTypeBusicr = async (): Promise<MasterEmpTypeBusicr[]> => {
  const res = await axios.get(endpoint.masterDataEmpTypeBusicr);
  return res.data.masterData.empTypeBusicr;
};
/** 現職区分マスタ */
export const getMasterDataListWkStatus = async (): Promise<MasterDataWkStatus[]> => {
  const res = await axios.get(endpoint.masterDataWkStatus);
  return res.data.masterData.wkStatus;
};
export const getMasterDataListIndsctg = async (): Promise<MasterDataIndsctg> => {
  const res = await axios.get(endpoint.masterDataIndsctg);
  return res.data.mstIndsctgLargeDataList;
};

export const getMasterDataOcupClass = async (): Promise<MasterDataOcupClass> => {
  const res = await axios.get(endpoint.masterDataOcupClass);
  return res.data.mstOcup1ClassDataList;
};

export const getMasterDataListPref = async (): Promise<Pref[]> => {
  const res = await axios.get(endpoint.masterDataPref);
  return res.data.masterData.pref;
};

//** 郵便番号マスタ*/
export const getMasterDataPostCode = async (postCode: string): Promise<PostCodeList> => {
  const res = await axios.get(endpoint.masterDataPostCode + postCode);
  return res.data.zipcodeDataList;
};

/** 面談形態マスタ */
export const getMasterDataMainRegTypeCareer = async (): Promise<MasterDataMainRegTypeCareer[]> => {
  const res = await axios.get(endpoint.masterDataMainRegTypeCareer);
  return res.data.masterData.mainRegTypeCareer;
};

/** 学歴マスタ */
export const getMasterDataListLstSchl = async (): Promise<MasterDataLstSchl[]> => {
  const res = await axios.get(endpoint.masterDataLstSchl);
  return res.data.masterData.lstSchl;
};

/** 入学区分マスタ */
export const getMasterDataListEntrance = async (): Promise<MasterDataEntrance[]> => {
  const res = await axios.get(endpoint.masterDataEntrance);
  return res.data.masterData.entrance;
};

/** 卒業区分マスタ */
export const getMasterDataListGrad = async (): Promise<MasterDataGrad[]> => {
  const res = await axios.get(endpoint.masterDataGrad);
  return res.data.masterData.grad;
};

/** 語学スキルマスタ */
export const getMasterDataLangSkl = async (): Promise<MasterDataLangSkl[]> => {
  const res = await axios.get(endpoint.masterDataLangSkl);
  return res.data.masterData.langSkl;
};

/** 外国語マスタ */
export const getMasterOtrLang = async (): Promise<MasterDataOtrLang[]> => {
  const res = await axios.get(endpoint.masterDataOtrLang);
  return res.data.masterData.otrLang;
};
/** 求人検索用勤務地情報マスタ */
export const getMasterSearchConditionWorkArea = async (): Promise<MasterDataSearchConditionWorkArea[]> => {
  const res = await axios.get(endpoint.masterDataSearchConditionWorkArea);
  return res.data.searchConditionWorkAreaDataList;
};
