import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getCareerId } from '@/api/careerIdEndpoint';

type CareerIdState = {
    careerId?: number | null;
};

const careerIdModule: Module<CareerIdState, RootState> = {
    namespaced: true,
    state: {
        careerId: null,
    },
    getters: {
        careerId(state) {
            return state.careerId;
        },
    },

    mutations: {
        set(state, value: CareerIdState) {
            Object.assign(state, value);
        },
    },

    actions: {
        async receive({ commit }) {
            const careerId = await getCareerId();
            commit('set', careerId);
        },
    },
};

export default careerIdModule;