import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

import LoginView from '@/views/login/LoginView.vue';
import LoginForgetView from '@/views/login/LoginForgetView.vue';
import LoginForgetCompleteView from '@/views/login/LoginForgetCompleteView.vue';
import PasswordReset from '@/views/login/PasswordReset.vue';
import PasswordResetComplete from '@/views/login/PasswordResetComplete.vue';
import ExperienceView from '@/views/entry-base/ExperienceView.vue';
import LocationView from '@/views/entry-base/LocationView.vue';
import BusinesstypeView from '@/views/entry-base/BusinesstypeView.vue';
import ProfileView from '@/views/entry-base/ProfileView.vue';
import CueView from '@/views/entry-base/CueView.vue';
import RegistaccountView from '@/views/entry-base/RegistaccountView.vue';
import RegistComplete from '@/views/entry-base/RegistComplete.vue';
import RegistError from '@/views/entry-base/RegistError.vue';
import Registering from '@/views/entry-base/Registering.vue';
import AddressView from '@/views/entry-career/AddressView.vue';
import EducationView from '@/views/entry-career/EducationView.vue';
import ResumeView from '@/views/entry-career/ResumeView.vue';
import CompanyView from '@/views/entry-career/CompanyView.vue';
import CompanyconfirmView from '@/views/entry-career/CompanyconfirmView.vue';
import EntryCareerDesiredView from '@/views/entry-career/DesiredView.vue';
import SkilllanguageView from '@/views/entry-career/SkilllanguageView.vue';
import SkillsoftwareView from '@/views/entry-career/SkillsoftwareView.vue';
import SkillpclanguageView from '@/views/entry-career/SkillpclanguageView.vue';
import SkilllicenseView from '@/views/entry-career/SkilllicenseView.vue';
import SkillbusinesstypeView from '@/views/entry-career/SkillbusinesstypeView.vue';
import CareercompleteView from '@/views/entry-career/CompleteView.vue';
import AppointmentView from '@/views/appointment/AppointmentView.vue';
import AppointmentconfirmView from '@/views/appointment/AppointmentconfirmView.vue';
import AppointmentcompleteView from '@/views/appointment/AppointmentcompleteView.vue';
import AppointmentchangeView from '@/views/appointment/AppointmentchangeView.vue';
import AppointmentcancelView from '@/views/appointment/AppointmentcancelView.vue';
import AppointmentcancelcompleteView from '@/views/appointment/AppointmentcancelcompleteView.vue';
import SearchView from '@/views/search/Index.vue';
import SearchlistView from '@/views/search/ListView.vue';
import HomeView from '@/views/home/Index.vue';
import MylistView from '@/views/mylist/MylistView.vue';
import AppliedView from '@/views/applied/AppliedView.vue';
import RecommendView from '@/views/recommend/RecommendView.vue';
import DeclineView from '@/views/decline/DeclineView.vue';
import Provision from '@/views/provision/index.vue';
import ProvisionView from '@/views/provision/ProvisionView.vue';
import ProvisionconfirmView from '@/views/provision/ProvisionconfirmView.vue';
import MenuProvision from '@/views/menu/provision/index.vue';
import MenuProvisionView from '@/views/menu/provision/ProvisionView.vue';
import MenuProvisionconfirmView from '@/views/menu/provision/ProvisionconfirmView.vue';
import CareersheetProfileView from '@/views/careersheet/ProfileView.vue';
import MenuProfileView from '@/views/menu/ProfileView.vue';
import CareersheetProfileIndexView from '@/views/careersheet/profile/index.vue';
import CareersheetProfileEditView from '@/views/careersheet/profile/ProfileEditView.vue';
import CareersheetProfileConfirmView from '@/views/careersheet/profile/ProfileConfirmView.vue';
import CareersheetMailIndexView from '@/views/careersheet/mail/index.vue';
import CareersheetMailEditView from '@/views/careersheet/mail/MailEditView.vue';
import CareersheetMailConfirmView from '@/views/careersheet/mail/MailConfirmView.vue';
import CareersheetPasswordEditView from '@/views/careersheet/PasswordEditView.vue';
import CareersheetResumeView from '@/views/careersheet/ResumeView.vue';
import MenuResumeView from '@/views/menu/ResumeView.vue';
import CareersheetResumeEditParentView from '@/views/careersheet/resumeEdit/index.vue';
import CareersheetResumeEditView from '@/views/careersheet/resumeEdit/ResumeEditView.vue';
import CareersheetResumeConfirmView from '@/views/careersheet/resumeEdit/ResumeConfirmView.vue';
import CareersheetCompanyParentView from '@/views/careersheet/company/index.vue';
import CareersheetCompanyView from '@/views/careersheet/company/CompanyView.vue';
import CareersheetCompanyAddView from '@/views/careersheet/company/CompanyAddView.vue';
import CareersheetCompanyEditView from '@/views/careersheet/company/CompanyEditView.vue';
import CareersheetCompanyConfirmView from '@/views/careersheet/company/CompanyConfirmView.vue';
import CareersheetDesiredView from '@/views/careersheet/DesiredView.vue';
import MenuDesiredView from '@/views/menu/DesiredView.vue';
import CareersheetDesiredDesiredView from '@/views/careersheet/desired/index.vue';
import CareersheetDesiredEditView from '@/views/careersheet/desired/DesiredEditView.vue';
import CareersheetDesiredConfirmView from '@/views/careersheet/desired/DesiredConfirmView.vue';
import CareersheetEducationSkillView from '@/views/careersheet/EducationSkillView.vue';
import MenuEducationSkillView from '@/views/menu/EducationSkillView.vue';
import CareersheetEducationView from '@/views/careersheet/education/index.vue';
import CareersheetEducationEditView from '@/views/careersheet/education/EducationEditView.vue';
import CareersheetEducationConfirmView from '@/views/careersheet/education/EducationConfirmView.vue';
import CareersheetSkillLicenseIndexView from '@/views/careersheet/skillLicense/index.vue';
import CareersheetSkillLicenseEditView from '@/views/careersheet/skillLicense/SkillLicenseEditView.vue';
import CareersheetSkillLicenseConfirmView from '@/views/careersheet/skillLicense/SkillLicenseConfirmView.vue';
import CareersheetSkillLanguageView from '@/views/careersheet/skillLanguage/index.vue';
import CareersheetSkillLanguageEditView from '@/views/careersheet/skillLanguage/SkillLanguageEditView.vue';
import CareersheetSkillConfirmEditView from '@/views/careersheet/skillLanguage/SkillLanguageConfirmView.vue';
import CareersheetSkillSoftwareView from '@/views/careersheet/skillSoftware/index.vue';
import CareersheetSkillPclanguageView from '@/views/careersheet/skillPclanguage/index.vue';
import CareersheetSkillSoftwareEditView from '@/views/careersheet/skillSoftware/SkillSoftwareEditView.vue';
import CareersheetSkillSoftwareConfirmCView from '@/views/careersheet/skillSoftware/SkillSoftwareConfirmView.vue';
import CareersheetSkillPclanguageEditView from '@/views/careersheet/skillPclanguage/SkillPclanguageEditView.vue';
import CareersheetSkillPclanguageConfirmView from '@/views/careersheet/skillPclanguage/SkillPclanguageConfirmView.vue';
import CareersheetSkillBusinesstypeView from '@/views/careersheet/skillBusinesstype/index.vue';
import CareersheetSkillBusinesstypeEditView from '@/views/careersheet/skillBusinesstype/SkillBusinesstypeEditView.vue';
import CareersheetSkillBusinesstypeConfirmView from '@/views/careersheet/skillBusinesstype/SkillBusinesstypeConfirmView.vue';
import OfferdetailView from '@/views/offer/DetailView.vue';
import DeactivateView from '@/views/deactivate/DeactivateView.vue';
import DeactivateCompleteView from '@/views/deactivate/DeactivateCompleteView.vue';
import RestartAccountView from '@/views/restart-account/RestartAccountView.vue';
import RestartAccountCompleteView from '@/views/restart-account/RestartAccountCompleteView.vue';
import RestartAccountPasswordResetView from '@/views/restart-account/RestartAccountPasswordReset.vue';
import RestartAccountPasswordResetCompleteView from '@/views/restart-account/RestartAccountPasswordResetComplete.vue';
import LoginErrorForRestartAccountView from '@/views/login/LoginErrorForRestartAccount.vue';
import ErrorView from '@/views/error/ErrorView.vue';
import NotFoundView from '@/views/error/NotFoundView.vue';

export type Title = string;
export type Header = 'none' | 'typeA' | 'typeB' | 'typeC' | 'typeD';
export type HeaderTitle = string;
export type Footer = boolean;
export type NavBar = boolean;
export type SpHeaderBackLink = boolean;

export type Route = RouteRecordRaw & {
  meta?: RouteRecordRaw['meta'] & {
    title?: Title;
    header?: Header;
    headerTitle?: HeaderTitle;
    footer?: Footer;
    navBar?: NavBar;
    spHeaderBackLink?: SpHeaderBackLink;
  };
};

const routes: Array<Route> = [
  // A_10 ログイン
  {
    strict: true,
    path: '/login/',
    name: 'a10',
    component: LoginView,
    meta: {
      title: 'ログイン｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // A_20 パスワードを忘れた方
  {
    strict: true,
    path: '/login/forget/',
    name: 'a20',
    component: LoginForgetView,
    meta: {
      title: 'パスワードをお忘れの方｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // A_21 パスワード再設定の案内送信完了
  {
    strict: true,
    path: '/login/forget-complete/',
    name: 'a21',
    component: LoginForgetCompleteView,
    meta: {
      title: 'パスワードをお忘れの方（案内メール送信完了）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // A_22 パスワード再設定
  {
    strict: true,
    path: '/login/password-reset/:uniqueKey/',
    name: 'a22',
    component: PasswordReset,
    meta: {
      title: 'パスワード再設定｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // A_23 パスワード再設定完了
  {
    strict: true,
    path: '/login/password-reset-complete/',
    name: 'a23',
    component: PasswordResetComplete,
    meta: {
      title: 'パスワード再設定（完了）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // A_31 ログイン失敗（運用再開）
  {
    strict: true,
    path: '/login/error/restart-account/',
    name: 'a31',
    component: LoginErrorForRestartAccountView,
    meta: {
      title: 'ログイン（エラー）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // B_00 経験の有無
  {
    strict: true,
    path: '/entry-base/experience/',
    name: 'b00',
    component: ExperienceView,
    meta: {
      title: '転職支援サービスお申し込み（経験の有無）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_01 ご希望の勤務地
  {
    strict: true,
    path: '/entry-base/location/',
    name: 'b01',
    component: LocationView,
    meta: {
      title: '転職支援サービスお申し込み（希望勤務地）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_02 業種の選択
  {
    strict: true,
    path: '/entry-base/businesstype/',
    name: 'b02',
    component: BusinesstypeView,
    meta: {
      title: '転職支援サービスお申し込み（業種・職種）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_04 氏名と性別
  {
    strict: true,
    path: '/entry-base/profile/',
    name: 'b04',
    component: ProfileView,
    meta: {
      title: '転職支援サービスお申し込み（氏名）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_06 マスメディアンを知ったきっかけ
  {
    strict: true,
    path: '/entry-base/cue/',
    name: 'b06',
    component: CueView,
    meta: {
      title:
        '転職支援サービスお申し込み（マスメディアンを知ったきっかけ）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_07 メールアドレスとパスワード
  {
    strict: true,
    path: '/entry-base/regist-account/',
    name: 'b07',
    component: RegistaccountView,
    meta: {
      title: '転職支援サービスお申し込み（メールアドレス・パスワード）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_08 登録完了
  {
    strict: true,
    path: '/entry-base/complete/',
    name: 'b08',
    component: RegistComplete,
    meta: {
      title: '転職支援サービスお申し込み（登録完了）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // B_09 登録エラー(クエリで判別)
  {
    strict: true,
    path: '/entry-base/regist-error/',
    name: 'b09',
    component: RegistError,
    meta: {
      title: '転職支援サービスお申し込み（エラー）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
    props: (route) => ({
      code: route.query.code,
    }),
  },
  // B_10 登録中
  {
    strict: true,
    path: '/entry-base/registering/',
    name: 'b10',
    component: Registering,
    meta: {
      title: '転職支援サービスお申し込み（登録中）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: '転職支援サービスお申し込み（無料）',
      footer: true,
    },
  },
  // C_00 キャリアシート入力（ご連絡先）
  {
    strict: true,
    path: '/entry-career/address/',
    name: 'c00',
    component: AddressView,
    meta: {
      title: 'キャリアシート入力（連絡先）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_01 キャリアシート入力（学歴）
  {
    strict: true,
    path: '/entry-career/education/',
    name: 'c01',
    component: EducationView,
    meta: {
      title: 'キャリアシート入力（学歴）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_02 キャリアシート入力（社会人経験）
  {
    strict: true,
    path: '/entry-career/resume/',
    name: 'c02',
    component: ResumeView,
    meta: {
      title: 'キャリアシート入力（社会人経験）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_03 キャリアシート入力
  {
    strict: true,
    path: '/entry-career/company/',
    name: 'c03',
    component: CompanyView,
    meta: {
      title: 'キャリアシート入力（職歴）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_04 キャリアシート入力（職歴確認画面）
  {
    strict: true,
    path: '/entry-career/company-confirm/',
    name: 'c04',
    component: CompanyconfirmView,
    meta: {
      title: 'キャリアシート入力（職歴）の確認｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_07 キャリアシート入力（希望条件）
  {
    strict: true,
    path: '/entry-career/desired/',
    name: 'c07',
    component: EntryCareerDesiredView,
    meta: {
      title: 'キャリアシート入力（希望条件）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_08 キャリアシート入力（言語）
  {
    strict: true,
    path: '/entry-career/skill-language/',
    name: 'c08',
    component: SkilllanguageView,
    meta: {
      title: 'キャリアシート入力（言語）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_09 キャリアシート入力（PCソフト）
  {
    strict: true,
    path: '/entry-career/skill-software/',
    name: 'c09',
    component: SkillsoftwareView,
    meta: {
      title: 'キャリアシート入力（PCソフト）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },

  // C_10 キャリアシート入力（プログラミング言語）
  {
    strict: true,
    path: '/entry-career/skill-pclanguage/',
    name: 'c10',
    component: SkillpclanguageView,
    meta: {
      title: 'キャリアシート入力（プログラミング言語）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_11 キャリアシート入力（資格）
  {
    strict: true,
    path: '/entry-career/skill-license/',
    name: 'c11',
    component: SkilllicenseView,
    meta: {
      title: 'キャリアシート入力（資格）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_12 キャリアシート入力（経験業種）
  {
    strict: true,
    path: '/entry-career/skill-businesstype/',
    name: 'c12',
    component: SkillbusinesstypeView,
    meta: {
      title: 'キャリアシート入力（クライアント業種）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },
  // C_13 キャリアシート入力完了
  {
    strict: true,
    path: '/entry-career/complete/',
    name: 'c13',
    component: CareercompleteView,
    meta: {
      title: 'キャリアシート入力（登録完了）｜マイページ｜マスメディアン',
      header: 'typeA',
      headerTitle: 'キャリアシート入力',
      footer: true,
    },
  },

  // D_00_面談予約
  {
    strict: true,
    path: '/appointment/',
    name: 'd00',
    component: AppointmentView,
    meta: {
      title: '面談予約｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談予約',
      backGround: 'is-gray',
    },
  },
  // D_01 面談予約（確認画面）
  {
    strict: true,
    path: '/appointment/confirm/',
    name: 'd01',
    component: AppointmentconfirmView,
    meta: {
      title: '面談予約（日程の確認）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談予約',
    },
  },
  // D_02 面談予約（完了画面）
  {
    strict: true,
    path: '/appointment/complete/',
    name: 'd02',
    component: AppointmentcompleteView,
    meta: {
      title: '面談予約（予約完了）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談予約',
    },
  },
  // D_10 面談予約（日程の変更・キャンセル）
  {
    strict: true,
    path: '/appointment/change/',
    name: 'd10',
    component: AppointmentchangeView,
    meta: {
      title: '面談予約（日程の変更・キャンセル）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談日程の変更・キャンセル',
    },
  },
  // D_11 面談予約（キャンセル確認）
  {
    strict: true,
    path: '/appointment/cancel-confirm/',
    name: 'd11',
    component: AppointmentcancelView,
    meta: {
      title: '面談予約（日程の変更・キャンセルの確認）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談日程の変更・キャンセル',
    },
  },
  // D_12 面談予約（キャンセル・日程の変更）
  {
    strict: true,
    path: '/appointment/cancel-complete/',
    name: 'd12',
    component: AppointmentcancelcompleteView,
    meta: {
      title: '面談予約（日程の変更・キャンセルの完了）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '面談日程の変更・キャンセル',
    },
  },
  // 利用規約の確認
  {
    strict: true,
    path: '/provision/',
    component: Provision,
    children: [
      // E_10 利用規約の確認
      {
        strict: true,
        path: '/provision/',
        name: 'e10',
        component: ProvisionView,
        meta: {
          title: '利用規約｜マイページ｜マスメディアン',
          header: 'typeD',
          headerTitle: 'マイページ',
        },
      },
      // E_11 利用規約の確認_確認画面
      {
        strict: true,
        path: '/provision/confirm/',
        name: 'e11',
        component: ProvisionconfirmView,
        meta: {
          title: '利用規約（完了）｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
          spHeaderBackLink: true,
        },
      },
    ],
  },
  {
    strict: true,
    path: '/menu/provision/',
    component: MenuProvision,
    children: [
      // N_10 利用規約の確認
      {
        strict: true,
        path: '/menu/provision/',
        name: 'n10',
        component: MenuProvisionView,
        meta: {
          title: '利用規約｜マイページ｜マスメディアン',
          header: 'typeD',
          headerTitle: 'マイページ',
        },
      },
      // N_11 利用規約の確認_確認画面
      {
        strict: true,
        path: '/menu/provision/confirm/',
        name: 'n11',
        component: MenuProvisionconfirmView,
        meta: {
          title: '利用規約（確認）｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: '利用規約への同意',
        },
      },
    ],
  },
  // E_20 基本情報_確認画面
  {
    strict: true,
    path: '/careersheet/profile-account/',
    name: 'e20',
    component: CareersheetProfileView,
    meta: {
      title: 'キャリアシート（基本情報）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
    },
  },
  // N_20 基本情報_確認画面
  {
    strict: true,
    path: '/menu/profile-account/',
    name: 'n20',
    component: MenuProfileView,
    meta: {
      title: '基本情報｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
      spHeaderBackLink: true,
    },
  },
  // 基本情報
  {
    strict: true,
    path: '/careersheet/profile-account/profile/',
    component: CareersheetProfileIndexView,
    children: [
      // E_21,N_21 基本情報_編集画面
      {
        strict: true,
        path: '/careersheet/profile-account/profile/edit/',
        name: 'e21',
        component: CareersheetProfileEditView,
        meta: {
          title: 'キャリアシート（基本情報）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_22,N_22 基本情報_確認画面
      {
        strict: true,
        path: '/careersheet/profile-account/profile/confirm/',
        name: 'e22',
        component: CareersheetProfileConfirmView,
        meta: {
          title: 'キャリアシート（基本情報）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // メールアドレス
  {
    strict: true,
    path: '/careersheet/profile-account/mail/',
    component: CareersheetMailIndexView,
    children: [
      // E_23,N_23 メールアドレス_編集画面
      {
        strict: true,
        path: '/careersheet/profile-account/mail/edit/',
        name: 'e23',
        component: CareersheetMailEditView,
        meta: {
          title: 'キャリアシート（メールアドレス）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_24,N_24 メールアドレス_確認画面
      {
        strict: true,
        path: '/careersheet/profile-account/mail/confirm/',
        name: 'e24',
        component: CareersheetMailConfirmView,
        meta: {
          title: 'キャリアシート（メールアドレス）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // E_25,N_25 パスワード_編集画面
  {
    strict: true,
    path: '/careersheet/profile-account/password/edit/',
    name: 'e25',
    component: CareersheetPasswordEditView,
    meta: {
      title: 'キャリアシート（パスワード）の編集｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
    },
  },
  // E_30 社会人経験_閲覧画面
  {
    strict: true,
    path: '/careersheet/resume-company/',
    name: 'e30',
    component: CareersheetResumeView,
    meta: {
      title: 'キャリアシート（社会人経験）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
    },
  },
  // N_30 社会人経験_閲覧画面
  {
    strict: true,
    path: '/menu/resume-company/',
    name: 'n30',
    component: MenuResumeView,
    meta: {
      title: 'キャリアシート（社会人経験）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
      spHeaderBackLink: true,
    },
  },
  // 社会人経験_編集
  {
    strict: true,
    path: '/careersheet/resume-company/resume/',
    component: CareersheetResumeEditParentView,
    children: [
      // E_31,N_31 社会人経験_編集画面
      {
        strict: true,
        path: '/careersheet/resume-company/resume/edit/',
        name: 'e31',
        component: CareersheetResumeEditView,
        meta: {
          title: 'キャリアシート（社会人経験）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_32,N_32 社会人経験_編集後確認画面
      {
        strict: true,
        path: '/careersheet/resume-company/resume/confirm/',
        name: 'e32',
        component: CareersheetResumeConfirmView,
        meta: {
          title: 'キャリアシート（社会人経験）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // 職歴
  {
    strict: true,
    path: '/careersheet/resume-company/company/',
    component: CareersheetCompanyParentView,
    children: [
      // E_33,N_33 職歴_閲覧画面
      {
        strict: true,
        path: '/careersheet/resume-company/company/',
        name: 'e33',
        component: CareersheetCompanyView,
        meta: {
          title: 'キャリアシート（職歴）｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_34,N_34 職歴_編集画面
      {
        strict: true,
        path: '/careersheet/resume-company/company/edit/',
        name: 'e34',
        component: CareersheetCompanyEditView,
        meta: {
          title: 'キャリアシート（職歴）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_35,N_35 職歴_新規登録
      {
        strict: true,
        path: '/careersheet/resume-company/company/add/',
        name: 'e35',
        component: CareersheetCompanyAddView,
        meta: {
          title: 'キャリアシート（職歴）の追加｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_36,N_36 職歴_確認画面
      {
        strict: true,
        path: '/careersheet/resume-company/company/confirm/',
        name: 'e36',
        component: CareersheetCompanyConfirmView,
        meta: {
          title: 'キャリアシート（職歴）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // E_40 希望条件_閲覧画面
  {
    strict: true,
    path: '/careersheet/desired/',
    name: 'e40',
    component: CareersheetDesiredView,
    meta: {
      title: 'キャリアシート（希望条件）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
    },
  },
  // N_40 希望条件_閲覧画面
  {
    strict: true,
    path: '/menu/desired/',
    name: 'n40',
    component: MenuDesiredView,
    meta: {
      title: 'キャリアシート（希望条件）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
      spHeaderBackLink: true,
    },
  },
  // 希望条件_編集
  {
    strict: true,
    path: '/careersheet/desired/desired/',
    component: CareersheetDesiredDesiredView,
    children: [
      // E_41,N_41 希望条件_編集画面
      {
        strict: true,
        path: '/careersheet/desired/desired/edit/',
        name: 'e41',
        component: CareersheetDesiredEditView,
        meta: {
          title: 'キャリアシート（希望条件）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_42,N_42 希望条件_確認画面
      {
        strict: true,
        path: '/careersheet/desired/desired/confirm/',
        name: 'e42',
        component: CareersheetDesiredConfirmView,
        meta: {
          title: 'キャリアシート（希望条件）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },

  // E_50 学歴スキル_閲覧画面
  {
    strict: true,
    path: '/careersheet/education-skill/',
    name: 'e50',
    component: CareersheetEducationSkillView,
    meta: {
      title: 'キャリアシート（学歴・スキル）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
    },
  },
  // N_50 学歴スキル_閲覧画面
  {
    strict: true,
    path: '/menu/education-skill/',
    name: 'n50',
    component: MenuEducationSkillView,
    meta: {
      title: 'キャリアシート（学歴・スキル）｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: 'マイページ',
      spHeaderBackLink: true,
    },
  },
  // 学歴_編集
  {
    strict: true,
    path: '/careersheet/education-skill/education/',
    component: CareersheetEducationView,
    children: [
      // E_51,N_51 学歴_編集
      {
        strict: true,
        path: '/careersheet/education-skill/education/edit/',
        name: 'e51',
        component: CareersheetEducationEditView,
        meta: {
          title: 'キャリアシート（学歴）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_52,N_52 学歴_確認
      {
        strict: true,
        path: '/careersheet/education-skill/education/confirm/',
        name: 'e52',
        component: CareersheetEducationConfirmView,
        meta: {
          title: 'キャリアシート（学歴）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // 資格
  {
    strict: true,
    path: '/careersheet/education-skill/skill-license/',
    component: CareersheetSkillLicenseIndexView,
    children: [
      // E_53,N_53 資格_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-license/edit/',
        name: 'e53',
        component: CareersheetSkillLicenseEditView,
        meta: {
          title: 'キャリアシート（資格）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_54,N_54 資格_確認
      {
        strict: true,
        path: '/careersheet/education-skill/skill-license/confirm/',
        name: 'e54',
        component: CareersheetSkillLicenseConfirmView,
        meta: {
          title: 'キャリアシート（資格）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // 言語
  {
    strict: true,
    path: '/careersheet/education-skill/skill-language/',
    component: CareersheetSkillLanguageView,
    children: [
      // E_55,N_55 言語_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-language/edit/',
        name: 'e55',
        component: CareersheetSkillLanguageEditView,
        meta: {
          title: 'キャリアシート（言語）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_56,N_56 言語_確認
      {
        strict: true,
        path: '/careersheet/education-skill/skill-language/confirm/',
        name: 'e56',
        component: CareersheetSkillConfirmEditView,
        meta: {
          title: 'キャリアシート（言語）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // PCソフト
  {
    strict: true,
    path: '/careersheet/education-skill/skill-software/',
    component: CareersheetSkillSoftwareView,
    children: [
      // E_57,N_57 PCソフト_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-software/edit/',
        name: 'e57',
        component: CareersheetSkillSoftwareEditView,
        meta: {
          title: 'キャリアシート（PCソフト）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_58,N_58 PCソフト_確認
      {
        strict: true,
        path: '/careersheet/education-skill/skill-software/confirm/',
        name: 'e58',
        component: CareersheetSkillSoftwareConfirmCView,
        meta: {
          title: 'キャリアシート（PCソフト）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // プログラミング言語(PC言語)
  {
    strict: true,
    path: '/careersheet/education-skill/skill-pclanguage/',
    component: CareersheetSkillPclanguageView,
    children: [
      // E_59,N_59 プログラミング言語(PC言語)_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-pclanguage/edit/',
        name: 'e59',
        component: CareersheetSkillPclanguageEditView,
        meta: {
          title: 'キャリアシート（プログラミング言語）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_60,N_60 プログラミング言語(PC言語)_確認
      {
        strict: true,
        path: '/careersheet/education-skill/skill-pclanguage/confirm/',
        name: 'e60',
        component: CareersheetSkillPclanguageConfirmView,
        meta: {
          title: 'キャリアシート（プログラミング言語）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // 経験業種
  {
    strict: true,
    path: '/careersheet/education-skill/skill-businesstype/',
    component: CareersheetSkillBusinesstypeView,
    children: [
      // E_61,N_61 経験業種_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-businesstype/edit/',
        name: 'e61',
        component: CareersheetSkillBusinesstypeEditView,
        meta: {
          title: 'キャリアシート（クライアント業種）の編集｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
      // E_62,N_62 経験業種_編集
      {
        strict: true,
        path: '/careersheet/education-skill/skill-businesstype/confirm/',
        name: 'e62',
        component: CareersheetSkillBusinesstypeConfirmView,
        meta: {
          title: 'キャリアシート（クライアント業種）の確認｜マイページ｜マスメディアン',
          header: 'typeC',
          headerTitle: 'マイページ',
        },
      },
    ],
  },
  // F_00 ホーム画面:通常利用
  {
    strict: true,
    path: '/home/',
    name: 'f00',
    component: HomeView,
    meta: {
      title: 'マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // G_00 求人詳細
  {
    strict: true,
    path: '/offer/detail/:orderId/',
    name: 'g00',
    component: OfferdetailView,
    props: true,
    meta: {
      title: '求人詳細（求人ナンバー）｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // H_00_a_求人検索
  {
    strict: true,
    path: '/search/',
    name: 'h00',
    component: SearchView,
    meta: {
      title: '求人検索｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // H_10 求人検索結果
  {
    strict: true,
    path: '/search/list/',
    name: 'h10',
    component: SearchlistView,
    meta: {
      title: '求人検索｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // I_00 検討中の求人
  {
    strict: true,
    path: '/mylist/',
    name: 'i00',
    component: MylistView,
    meta: {
      title: '検討中の求人｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // J_00 応募中の求人
  {
    strict: true,
    path: '/applied/',
    name: 'j00',
    component: AppliedView,
    meta: {
      title: '応募中の求人｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // L_00 おすすめの求人
  {
    strict: true,
    path: '/recommend/',
    name: 'l00',
    component: RecommendView,
    meta: {
      title: 'おすすめの求人｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // M_00 辞退した求人
  {
    strict: true,
    path: '/decline/',
    name: 'm00',
    component: DeclineView,
    meta: {
      title: '辞退した求人｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
      navBar: true,
      backGround: 'is-gray',
    },
  },
  // P_00_退会ページ
  // ヘッダー：PCではナビゲーション無し。（パンくず有り）
  // ヘッダー：SPでは「戻る」がある。（パンくず無し）
  {
    strict: true,
    path: '/deactivate/',
    name: 'p00',
    component: DeactivateView,
    meta: {
      title: '退会｜マイページ｜マスメディアン',
      header: 'typeC',
      headerTitle: '退会',
      spHeaderBackLink: true,
    },
  },
  // P_10_退会ページ
  // ヘッダー：PCではナビゲーション無し。（パンくず有り）
  {
    strict: true,
    path: '/deactivate-complete/',
    name: 'p10',
    component: DeactivateCompleteView,
    meta: {
      title: '退会（送信完了）｜マイページ｜マスメディアン',
      header: 'typeB',
      headerTitle: '退会完了',
    },
  },
  // Q_01 利用再開手続
  {
    strict: true,
    path: '/restart-account/',
    name: 'q01',
    component: RestartAccountView,
    meta: {
      title: '転職支援サービス再開お申し込み｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // Q_02 利用再開手続完了
  {
    strict: true,
    path: '/restart-account/completed/',
    name: 'q02',
    component: RestartAccountCompleteView,
    meta: {
      title: '転職支援サービス再開お申し込み（完了）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // Q_03 利用再開手続（パスワード設定）
  {
    strict: true,
    path: '/restart-account/password-reset/:restartAccountUniqueKey/',
    name: 'q03',
    component: RestartAccountPasswordResetView,
    meta: {
      title: '転職支援サービス再開お申し込み（パスワード設定）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // Q_04 利用再開手続（パスワード設定）完了
  {
    strict: true,
    path: '/restart-account/password-reset/complete/',
    name: 'q04',
    component: RestartAccountPasswordResetCompleteView,
    meta: {
      title: '転職支援サービス再開お申し込み（パスワード設定完了）｜マーケティング・クリエイティブの求人情報・転職支援はマスメディアン',
      header: 'typeA',
      headerTitle: 'マイページ',
    },
  },
  // XX_999 共通エラー系
  {
    strict: true,
    path: '/error/',
    name: 'xx999',
    component: ErrorView,
    meta: {
      title: 'エラーページ｜マイページ｜マスメディアン',
      header: 'typeD',
      headerTitle: 'マイページ',
    },
  },
  // 404
  {
    path: '/:catchAll(.*)',
    name: 'xx404',
    meta: {
      title: 'お探しのページが見つかりませんでした｜マイページ｜マスメディアン',
      header: 'typeA',
    },
    component: NotFoundView,
  },
  // 404(あえて表示する場合)
  {
    strict: true,
    path: '/not-found/',
    name: '404',
    meta: {
      title: 'お探しのページが見つかりませんでした｜マイページ｜マスメディアン',
      header: 'typeA',
    },
    component: NotFoundView,
  },
];

const base = '';
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL + base),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return;
      }
      return { top: 0 };
    }
  },
});

// title設定
router.beforeEach((to, from, next) => {
  // pathの末尾に/がない場合は、/を付与して遷移する
  if (to.path.slice(-1) !== '/') {
    const path = to.path + '/';
    const name = routes.filter((route) => route.name !== 'xx404' && route.path === path)[0]?.name || '';
    if (name) {
      next({ name, query: to.query || {}, params: to.params || {} });
      return;
    }
  }
  if (to.meta.title) {
    document.title = `${to.meta.title}`;
  }
  if (to.meta.backGround) {
    document.body.classList.add(to.meta.backGround as string);
  }
  if (from.meta.backGround && !to.meta.backGround) {
    document.body.className = '';
  }

  // NOTE: H_10 求人検索一覧画面にて、「もっと見る」を押してからブラウザ更新すると、求人一覧が消える現象対策
  if (from.name === undefined && to.name === 'h10' && to.hash === '#more') {
    to.hash = '';
    // hashを消すことで対応
    next({ name: 'h10' });
    return;
  }

  // 登録完了画面からブラウザバックで戻った場合、404に遷移する
  if (from.name === 'b08' && to.name === 'b10') {
    next({ name: '404'});
  }
  next();
});

export default router;
