import axios from '@/plugins/axios';
import { entryEndpoint } from '@/api/apiConstants';

export type Pref = {
  code: number;
  detail: null;
  name: string;
};

export type MasterDataOcupClass = {
  ocup1ClassId: string;
  ocup1ClassName: string;
  mstOcup2ClassDataList: {
    ocup2ClassId: string;
    ocup2ClassName: string;
    mstOcup3ClassDataList: {
      ocup3ClassId: string;
      ocup3ClassName: string;
    }[];
  }[];
}[];

export type MasterDataIndsctg = {
  indsctgLargeId: number;
  indsctgLargeName: string;
  mstIndsctgSmallDataList: {
    indsctgSmallId: number;
    indsctgSmallName: string;
  }[];
}[];

export type Master = {
  code: number;
  name: string;
  detail: null | string;
};

export type MasterDataSearchConditionWorkArea = {
  areaName: string;
  searchConditionWorkPrefDataList: {
    predId: number;
    prefName: string;
  }[];
};

type masterName =
  | 'pref'
  | 'sex'
  | 'empType'
  | 'empTypeBusicr'
  | 'entrance'
  | 'grad'
  | 'wkStatus'
  | 'langSkl'
  | 'otrLang'
  | 'lstSchl'
  | 'media'
  | 'mainRegTypeCareer';

export const getMasterDataList = async (name: masterName): Promise<Master[]> => {
  const res = await axios.get(entryEndpoint.master, { params: { targets: name } });
  return res.data.masterData[name];
};

export const getMasterDataListIndsctg = async (): Promise<MasterDataIndsctg> => {
  const res = await axios.get(entryEndpoint.masterDataIndsctg);
  return res.data.mstIndsctgLargeDataList;
};

export const getMasterDataOcupClass = async (): Promise<MasterDataOcupClass> => {
  const res = await axios.get(entryEndpoint.masterDataOcupClass);
  return res.data.mstOcup1ClassDataList;
};
