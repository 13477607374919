import axios, { AxiosInstance } from 'axios';
import { endpoint } from '@/api/apiConstants';
import router from '@/router';
import store from '@/store';

const API_BASE_URL = process.env.VUE_APP_API_ENDPOINT;

const axiosInstance: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});
const axiosInstanceUseRefreshToken: AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  config.headers = {
    ...config.headers,
    // Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
    'X-AUTHORIZATION-TOKEN': 'Bearer ' + localStorage.getItem('accessToken'),
  };
  return config;
});

type QueueItem = {
  resolve: (value?: string | PromiseLike<string> | undefined) => void;
  reject: (reason?: Error) => void;
};
let failedQueue: QueueItem[] = [];
let isRefreshing = false;

const processQueue = (error: Error | null) => {
  failedQueue.forEach((quene) => {
    if (error) {
      quene.reject(error);
    } else {
      quene.resolve();
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const originalRequest = error.config;
    const errorStatus: number = error.response.status;
    if (errorStatus === 401 && !originalRequest._retry) {
      console.error('unauthorized');
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }
      originalRequest._retry = true;
      isRefreshing = true;
      return new Promise((resolve, reject) => {
        axiosInstanceUseRefreshToken
          .post(
            endpoint.refreshToken,
            {},
            {
              // headers: { Authorization: 'Bearer ' + localStorage.getItem('refreshToken') },
              headers: { 'X-AUTHORIZATION-TOKEN': 'Bearer ' + localStorage.getItem('refreshToken') },
            }
          )
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem('accessToken', res.data.accessToken);
              localStorage.setItem('refreshToken', res.data.refreshToken);
              processQueue(null);
              resolve(axiosInstance(originalRequest));
            }
          })
          .catch((error) => {
            console.log(error);
            processQueue(error);
            store.dispatch('toasts/appendTimeToast', { label: 'ログインセッションが切れました', duration: null });
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');

            const loginPageName = 'a10';
            if (router.currentRoute.value.name && router.currentRoute.value.name !== loginPageName) {
              // アクセスしようとした認証必須ページをstoreに保存し、ログインページに遷移させる
              store.commit('router/setLoginTransitionData', router.currentRoute.value);
              router.push({ name: loginPageName });
            }
            reject(error);
          })
          .then(() => {
            isRefreshing = false;
          });
      });
    }
    if ([401, 500, 503].includes(errorStatus)) {
      // 401 認証エラー
      // 500 DBエラー
      // 503 メンテナンス
      store.commit('error/setErrorState', errorStatus);
      router.push({ name: 'xx999' });
    }

    // それ以外
    // 400 リクエストエラー
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosInstanceUseRefreshToken as noAuthAxios };
