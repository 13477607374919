import axios from '@/plugins/axios';
import { endpoint } from './apiConstants';

type Notice = {
    title: string;
    noticeUrl: string;
};

// お知らせ取得
export const getNotice = async (): Promise<Notice> => {
    const res = await axios.get<Notice>(endpoint.getNotice);
    return res.data;
};