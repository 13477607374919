import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { noAuthAxios } from '@/plugins/axios';
import { entryEndpoint } from '@/api/apiConstants';
import { putUserBasicInfo } from '@/api/careersheetEndpoint';
import { checkToken, getToken, refreshToken } from '@/api/csrfEndpoint';
import axios from 'axios';

type RegistrationState = {
  data: {
    adlandexpFlg: boolean | null;
    wishwkarea1Id: number | null;
    wishwkarea2Id: number | null;
    wishwkarea3Id: number | null;
    wishwkarea4Id: number | null;
    indsctgId: number | null;
    ocupId: string;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    sexFlg: number | null;
    sex: string;
    birthDate: string;
    mediaId: number | null;
    mediaNotes: string | null;
    mailAddress: string;
    password: string;
    telCellular: string;
    zipCode: string;
    prefId: string;
    pref: string;
    addr1: string;
    addr2: string;
    addr3: string;
  };
  csrf: {
    csrfToken: string;
    csrfRefreshToken: string;
  };
};

const userRegistrationModule: Module<RegistrationState, RootState> = {
  namespaced: true,

  state: {
    data: {
      adlandexpFlg: null,
      wishwkarea1Id: null,
      wishwkarea2Id: null,
      wishwkarea3Id: null,
      wishwkarea4Id: null,
      indsctgId: null,
      ocupId: '',
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      sexFlg: null,
      sex: '',
      birthDate: '',
      mediaId: null,
      mediaNotes: null,
      mailAddress: '',
      password: '',
      telCellular: '',
      zipCode: '',
      prefId: '',
      pref: '',
      addr1: '',
      addr2: '',
      addr3: '',
    },
    csrf: {
      csrfToken: '',
      csrfRefreshToken: '',
    },
  },
  getters: {
    adlandexpFlg(state) {
      return state.data.adlandexpFlg;
    },
    wishwkarea1Id(state) {
      return state.data.wishwkarea1Id;
    },
    wishwkarea2Id(state) {
      return state.data.wishwkarea2Id;
    },
    wishwkarea3Id(state) {
      return state.data.wishwkarea3Id;
    },
    wishwkarea4Id(state) {
      return state.data.wishwkarea4Id;
    },
    indsctgId(state) {
      return state.data.indsctgId;
    },
    ocupId(state) {
      return state.data.ocupId;
    },
    firstName(state) {
      return state.data.firstName;
    },
    lastName(state) {
      return state.data.lastName;
    },
    firstNameKana(state) {
      return state.data.firstNameKana;
    },
    lastNameKana(state) {
      return state.data.lastNameKana;
    },
    sexFlg(state) {
      return state.data.sexFlg;
    },
    sex(state) {
      return state.data.sex;
    },
    birthDate(state) {
      return state.data.birthDate;
    },
    mediaId(state) {
      return state.data.mediaId;
    },
    mediaNotes(state) {
      return state.data.mediaNotes;
    },
    mailAddress(state) {
      return state.data.mailAddress;
    },
    password(state) {
      return state.data.password;
    },
    telCellular(state) {
      return state.data.telCellular;
    },
    zipCode(state) {
      return state.data.zipCode;
    },
    prefId(state) {
      return state.data.prefId;
    },
    pref(state) {
      return state.data.pref;
    },
    addr1(state) {
      return state.data.addr1;
    },
    addr2(state) {
      return state.data.addr2;
    },
    addr3(state) {
      return state.data.addr3;
    },
    token(state) {
      return state.csrf.csrfToken;
    },
  },
  mutations: {
    setAdlandexpFlg(state, flg: boolean) {
      state.data.adlandexpFlg = flg;
    },

    setWishwkarea1Id(state, id: number) {
      state.data.wishwkarea1Id = id;
    },
    setWishwkarea2Id(state, id: number) {
      state.data.wishwkarea2Id = id;
    },
    setWishwkarea3Id(state, id: number) {
      state.data.wishwkarea3Id = id;
    },
    setWishwkarea4Id(state, id: number) {
      state.data.wishwkarea4Id = id;
    },
    setIndsctgId(state, value: number) {
      state.data.indsctgId = value;
    },
    setOcupId(state, value: string) {
      state.data.ocupId = value;
    },
    setFirstName(state, value: string) {
      state.data.firstName = value;
    },
    setLastName(state, value: string) {
      state.data.lastName = value;
    },
    setFirstNameKana(state, value: string) {
      state.data.firstNameKana = value;
    },
    setLastNameKana(state, value: string) {
      state.data.lastNameKana = value;
    },
    setBirthDate(state, value: string) {
      state.data.birthDate = value;
    },
    setSexFlg(state, value: number) {
      state.data.sexFlg = value;
    },
    setSex(state, value: string) {
      state.data.sex = value;
    },
    setMediaId(state, value: number) {
      state.data.mediaId = value;
    },
    setMediaNotes(state, value: string) {
      state.data.mediaNotes = value;
    },
    setMailAddress(state, value: string) {
      state.data.mailAddress = value;
    },
    setPassword(state, value: string) {
      state.data.password = value;
    },
    setTelCellular(state, value: string) {
      state.data.telCellular = value;
    },
    setZipCode(state, value: string) {
      state.data.zipCode = value;
    },
    setPrefId(state, value: string) {
      state.data.prefId = value;
    },
    setPref(state, value: string) {
      state.data.pref = value;
    },
    setAddr1(state, value: string) {
      state.data.addr1 = value;
    },
    setAddr2(state, value: string) {
      state.data.addr2 = value;
    },
    setAddr3(state, value: string) {
      state.data.addr3 = value;
    },
    setCsrf(state, value: RegistrationState['csrf']) {
      Object.assign(state.csrf, value);
    },
  },
  actions: {
    async registAccount({ state, commit }) {
      try {
        await checkToken(state.csrf.csrfToken);
      } catch (error) {
        if (axios.isAxiosError(error) && error.code === '401') {
          const res = await refreshToken(state.csrf.csrfRefreshToken);
          commit('setCsrf', res);
          return;
        }
        throw error;
      }

      const res = await noAuthAxios.post(entryEndpoint.userRegistration, state.data, {
        headers: { 'X-CSRF-TOKEN': state.csrf.csrfToken },
      });
      localStorage.setItem('accessToken', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      return res;
    },
    async registBasicInfo({ state }) {
      const param = {
        firstName: state.data.firstName,
        lastName: state.data.lastName,
        firstNameKana: state.data.firstNameKana,
        lastNameKana: state.data.lastNameKana,
        birthDate: state.data.birthDate,
        sexFlg: Number(state.data.sexFlg),
        telCellular: state.data.telCellular,
        zipCode: state.data.zipCode,
        prefId: Number(state.data.prefId),
        addr1: state.data.addr1,
        addr2: state.data.addr2,
        addr3: state.data.addr3,
      };
      const res = await putUserBasicInfo(param);
      return res;
    },
    async getCsrfToken({ commit }) {
      const res = await getToken();
      commit('setCsrf', res);
    },
    async checkRegistAccount({ state, commit }) {
      try {
        await checkToken(state.csrf.csrfToken);
      } catch (error) {
        if (axios.isAxiosError(error) && error.code === '401') {
          const res = await refreshToken(state.csrf.csrfRefreshToken);
          commit('setCsrf', res);
          return;
        }
        throw error;
      }

      const res = await noAuthAxios.post(entryEndpoint.checkUserRegistration, state.data, {
        headers: { 'X-CSRF-TOKEN': state.csrf.csrfToken },
      });
      localStorage.setItem('accessToken', res.data.accessToken);
      localStorage.setItem('refreshToken', res.data.refreshToken);
      return res;
    },
  },
};

export default userRegistrationModule;
