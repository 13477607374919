import { Module } from 'vuex';
import { RootState } from '@/store/index';
import {
  getMasterDataListIndsctg,
  MasterDataIndsctg,
  getMasterDataOcupClass,
  MasterDataOcupClass,
  getMasterDataListEmpTypeBusicr,
  MasterEmpTypeBusicr,
  getMasterDataListPref,
  Pref,
  getMasterDataListLstSchl,
  MasterDataLstSchl,
  getMasterDataListEntrance,
  MasterDataEntrance,
  getMasterDataListGrad,
  MasterDataGrad,
  getMasterDataLangSkl,
  MasterDataLangSkl,
  getMasterOtrLang,
  MasterDataOtrLang,
  getMasterDataPostCode,
  PostCodeList,
} from '@/api/masterDataEndpoint';
import { RadioListType } from '@/types';

type MasterState = {
  mstIndsctg: MasterDataIndsctg | null; // 業種
  mstOcupClass: MasterDataOcupClass | null; // 職種
  mstEmpTypeBusicr: MasterEmpTypeBusicr[] | null; // 雇用形態(職歴)
  mstListPref: Pref[] | null; // 都道府県
  mstListLstSchl: MasterDataLstSchl[] | null;
  mstListEntrance: MasterDataEntrance[] | null;
  mstListGrad: MasterDataGrad[] | null;
  mstLangSkl: MasterDataLangSkl[] | null;
  mstOtrLangSkl: MasterDataOtrLang[] | null;
  postCodeList: PostCodeList | null; // 郵便番号
};

const masterModule: Module<MasterState, RootState> = {
  namespaced: true,

  state: {
    mstIndsctg: null,
    mstOcupClass: null,
    mstEmpTypeBusicr: null,
    mstListPref: null,
    mstListLstSchl: null,
    mstListEntrance: null,
    mstListGrad: null,
    mstLangSkl: null,
    mstOtrLangSkl: null,
    postCodeList: null,
  },
  getters: {
    // option形式に変換する
    optionFilter:
      () =>
      <T>(value: T) => {
        return value && Array.isArray(value)
          ? value.map((i) => {
              return {
                label: i.name,
                value: i.code,
              };
            })
          : [];
      },
    // 業種マスタ
    mstIndsctg(state) {
      return state.mstIndsctg;
    },
    // 業種マスタをオブジェクトにしたもの
    mstIndsctgFlat(state): { [key: number]: string } {
      if (!state.mstIndsctg) return {};
      const res = {};
      state.mstIndsctg.forEach((i) => {
        i.mstIndsctgSmallDataList.forEach((j) => {
          Object.assign(res, { [j.indsctgSmallId]: j.indsctgSmallName });
        });
        Object.assign(res, { [i.indsctgLargeId]: i.indsctgLargeName });
      });
      return res;
    },
    // 業種マスタのオブジェクト{親の値:子の値の配列}を返却する
    mstIndsctgParentToChildrenArray(state) {
      if (!state.mstIndsctg) return {};
      const res = {};
      state.mstIndsctg.forEach((i) => {
        Object.assign(res, { [i.indsctgLargeId]: i.mstIndsctgSmallDataList.map((j) => j.indsctgSmallId) });
      });
      return res;
    },
    // 業種マスタをRadioボタンのListTypeの形式にしたもの(親選択可能)
    mstIndsctgRadioList(state): RadioListType[] {
      if (!state.mstIndsctg) return [];
      return state.mstIndsctg.map((i) => {
        return {
          type: 'radio',
          label: i.indsctgLargeName,
          value: i.indsctgLargeId,
          children: i.mstIndsctgSmallDataList.map((j) => {
            return {
              type: 'radio',
              label: j.indsctgSmallName,
              value: j.indsctgSmallId,
            };
          }),
        };
      });
    },
    // 業種マスタをRadioボタンのListTypeの形式にしたもの(親選択不可)
    mstIndsctgRadioOnlyList(state): RadioListType[] {
      if (!state.mstIndsctg) return [];
      return state.mstIndsctg.map((i) => {
        return {
          type: 'default',
          label: i.indsctgLargeName,
          value: i.indsctgLargeId,
          children: i.mstIndsctgSmallDataList.map((j) => {
            return {
              type: 'radio',
              label: j.indsctgSmallName,
              value: j.indsctgSmallId,
            };
          }),
        };
      });
    },
    // 業種マスタをCheckboxのListTypeの形式にしたもの(親選択可能)
    mstIndsctgCheckboxList(state): RadioListType[] {
      if (!state.mstIndsctg) return [];
      return state.mstIndsctg.map((i) => {
        return {
          type: 'checkbox',
          label: i.indsctgLargeName,
          value: i.indsctgLargeId,
          children: i.mstIndsctgSmallDataList.map((j) => {
            return {
              type: 'checkbox',
              label: j.indsctgSmallName,
              value: j.indsctgSmallId,
            };
          }),
        };
      });
    },
    // 職種マスタ
    mstOcupClass(state) {
      return state.mstOcupClass;
    },
    // 職種マスタをオブジェクトにしたもの
    mstOcupClassFlat(state): { [key: number]: string } {
      if (!state.mstOcupClass) return {};
      const res = {};
      state.mstOcupClass.forEach((i) => {
        Object.assign(res, { [i.ocup1ClassId]: i.ocup1ClassName });
        i.mstOcup2ClassDataList.forEach((j) => {
          Object.assign(res, { [j.ocup2ClassId]: j.ocup2ClassName });
          j.mstOcup3ClassDataList.forEach((k) => {
            Object.assign(res, { [k.ocup3ClassId]: k.ocup3ClassName });
          });
        });
      });
      return res;
    },
    // 職種マスタのオブジェクト{親の値:子の値の配列}を返却する
    mstOcupClassParentToChildrenArray(state) {
      if (!state.mstOcupClass) return {};
      const res = {};
      state.mstOcupClass.forEach((i) => {
        const tmpRes: string[][] = [];
        i.mstOcup2ClassDataList.forEach((j) => {
          const tmp = j.mstOcup3ClassDataList.map((k) => k.ocup3ClassId);
          Object.assign(res, { [j.ocup2ClassId]: tmp });
          tmpRes.push(tmp);
        });
        Object.assign(res, { [i.ocup1ClassId]: tmpRes.flat() });
      });
      return res;
    },
    // 職種マスタを職歴複数選択（チェックボックス）のListTypeの形式にしたもの(親選択不可)
    mstOcupClassCheckboxList(state) {
      if (!state.mstOcupClass) return [];
      return state.mstOcupClass.map((i) => {
        return {
          type: 'default',
          label: i.ocup1ClassName,
          value: i.ocup1ClassId,
          children: i.mstOcup2ClassDataList.map((j) => {
            return {
              type: 'default',
              label: j.ocup2ClassName,
              value: j.ocup2ClassId,
              children: j.mstOcup3ClassDataList.map((k) => {
                return {
                  type: 'checkbox',
                  label: k.ocup3ClassName,
                  value: k.ocup3ClassId,
                };
              }),
            };
          }),
        };
      });
    },
    // 職種マスタを職歴複数選択（チェックボックス）のListTypeの形式にしたもの(親選択可)
    mstOcupClassAllCheckboxList(state) {
      if (!state.mstOcupClass) return [];
      return state.mstOcupClass.map((i) => {
        return {
          type: 'checkbox',
          label: i.ocup1ClassName,
          value: i.ocup1ClassId,
          children: i.mstOcup2ClassDataList.map((j) => {
            return {
              type: 'checkbox',
              label: j.ocup2ClassName,
              value: j.ocup2ClassId,
              children: j.mstOcup3ClassDataList.map((k) => {
                return {
                  type: 'checkbox',
                  label: k.ocup3ClassName,
                  value: k.ocup3ClassId,
                };
              }),
            };
          }),
        };
      });
    },
    // 職種マスタを職歴選択（ラジオボタン）のListTypeの形式にしたもの
    mstOcupClassRadioList(state) {
      if (!state.mstOcupClass) return [];
      return state.mstOcupClass.map((i) => {
        return {
          type: 'default',
          label: i.ocup1ClassName,
          value: i.ocup1ClassId,
          children: i.mstOcup2ClassDataList.map((j) => {
            return {
              type: 'default',
              label: j.ocup2ClassName,
              value: j.ocup2ClassId,
              children: j.mstOcup3ClassDataList.map((k) => {
                return {
                  type: 'radio',
                  label: k.ocup3ClassName,
                  value: k.ocup3ClassId,
                };
              }),
            };
          }),
        };
      });
    },
    // 雇用形態
    mstEmpTypeBusicr(state) {
      return state.mstEmpTypeBusicr;
    },
    /** 希望条件の雇用形態リスト */
    mstEmpTypeDesired(state): { key: 'empTypeFlg1' | 'empTypeFlg2' | 'empTypeFlg3' | 'empTypeFlg5'; label: string; value: number }[] {
      return state.mstEmpTypeBusicr
        ? (state.mstEmpTypeBusicr
            .filter((i) => [1, 2, 3, 5].includes(i.code))
            .map((i) => {
              return {
                value: i.code,
                label: i.name,
                key: `empTypeFlg${i.code}`,
              };
            }) as { key: 'empTypeFlg1' | 'empTypeFlg2' | 'empTypeFlg3' | 'empTypeFlg5'; label: string; value: number }[])
        : [];
    },
    mstListPref(state) {
      return state.mstListPref;
    },
    /** 都道府県の選択肢オプション */
    mstListPrefOptions(state, getters): { label: string; value: number }[] {
      return getters.optionFilter(state.mstListPref);
    },
    /** 学校種別の選択肢オプション */
    mstListLstSchlOptions(state, getters): { label: string; value: number }[] {
      return getters.optionFilter(state.mstListLstSchl);
    },
    /** 学校種別をcode:nameのobjectで返却する */
    mstListLstSchlObject(state) {
      return state.mstListLstSchl
        ? Object.fromEntries(
            state.mstListLstSchl.map((i) => {
              return [i.code, i.name];
            })
          )
        : {};
    },
    /** 入学/編入の選択肢オプション */
    mstListEntranceOptions(state, getters): { label: string; value: number }[] {
      return getters.optionFilter(state.mstListEntrance);
    },
    /** 入学/編入をcode:nameのobjectで返却する */
    mstListEntranceObject(state) {
      return state.mstListEntrance
        ? Object.fromEntries(
            state.mstListEntrance.map((i) => {
              return [i.code, i.name];
            })
          )
        : {};
    },
    /** 卒業/中退の選択肢オプション */
    mstListGradOptions(state, getters): { label: string; value: number }[] {
      return getters.optionFilter(state.mstListGrad);
    },
    /** 卒業/中退をcode:nameのobjectで返却する */
    mstListGradObject(state) {
      return state.mstListGrad
        ? Object.fromEntries(
            state.mstListGrad.map((i) => {
              return [i.code, i.name];
            })
          )
        : {};
    },
    /** 言語(英語)の選択肢オプション */
    mstListLanguageOptions(state): { label: string; subLabel: string; value: number }[] {
      if (!state.mstLangSkl) return [];
      return state.mstLangSkl.map((i) => {
        return {
          type: 'radio',
          label: i.name,
          subLabel: i.detail,
          value: i.code,
        };
      });
    },
    /** 言語(英語)をcode:nameのobjectで返却する */
    mstListLanguageObject(state) {
      return state.mstLangSkl
        ? Object.fromEntries(
            state.mstLangSkl.map((i) => {
              return [i.code, i.name];
            })
          )
        : {};
    },
    /** 言語(他言語)の選択肢オプション */
    mstListOtrLanguageOptions(state, getters): { label: string; value: number }[] {
      return getters.optionFilter(state.mstOtrLangSkl);
    },
    /** 言語(他言語)をcode:nameのobjectで返却する */
    mstListOtrLanguageObject(state) {
      return state.mstOtrLangSkl
        ? Object.fromEntries(
            state.mstOtrLangSkl.map((i) => {
              return [i.code, i.name];
            })
          )
        : {};
    },
    // 郵便番号から取得した住所情報
    postCodeList(state) {
      return state.postCodeList;
    },
    // 郵便番号から取得した住所情報をRadioボタンのListTypeの形式にしたもの(親選択不可)
    postCodeRadioList(state): RadioListType[] {
      if (!state.postCodeList) return [];
      return state.postCodeList.map((i) => {
        // 町名番地号がnullの場合、セットしない
        let address = i.prefName + i.cityName;
        if (i.blockName !== null) {
          address = address + i.blockName ;
        };
        return {
          type: 'radio',
          label: address,
          value: i.zipId,
        };
      });
    },
    // 住所情報をオブジェクトにしたもの
    postCode(state): { [key: number]: string } {
      if (!state.postCodeList) return {};
      const res = {};
      state.postCodeList.forEach((i) => {
        const map = new Map<string, string>();
        map.set('prefId', i.prefId.toString());
        map.set('cityName', i.cityName);
        map.set('blockName', i.blockName);
        Object.assign(res, {[i.zipId]: map });
      });
      return res;
    },
  },

  mutations: {
    setMstIndsctg(state, value) {
      state.mstIndsctg = value;
    },
    setMstOcupClass(state, value) {
      state.mstOcupClass = value;
    },
    setMstEmpTypeBusicr(state, value) {
      state.mstEmpTypeBusicr = value;
    },
    setMasterDataListPref(state, value) {
      state.mstListPref = value;
    },
    setMasterDataListLstSchl(state, value) {
      state.mstListLstSchl = value;
    },
    setMasterDataListEntrance(state, value) {
      state.mstListEntrance = value;
    },
    setMasterDataListGrad(state, value) {
      state.mstListGrad = value;
    },
    setMasterDataLangSkl(state, value) {
      state.mstLangSkl = value;
    },
    setMasterDataOtrLangSkl(state, value) {
      state.mstOtrLangSkl = value;
    },
    setPostcodeList(state, value) {
      state.postCodeList = value;
    },
  },
  actions: {
    // 業種マスタ取得
    async getMasterDataListIndsctg({ commit }) {
      try {
        const res = await getMasterDataListIndsctg();
        commit('setMstIndsctg', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    // 職種マスタ取得
    async getMasterDataOcupClass({ commit }) {
      try {
        const res = await getMasterDataOcupClass();
        commit('setMstOcupClass', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataListEmpTypeBusicr({ commit }) {
      try {
        const res = await getMasterDataListEmpTypeBusicr();
        commit('setMstEmpTypeBusicr', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataListPref({ commit }) {
      try {
        const res = await getMasterDataListPref();
        commit('setMasterDataListPref', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataListLstSchl({ commit }) {
      try {
        const res = await getMasterDataListLstSchl();
        commit('setMasterDataListLstSchl', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataListEntrance({ commit }) {
      try {
        const res = await getMasterDataListEntrance();
        commit('setMasterDataListEntrance', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataListGrad({ commit }) {
      try {
        const res = await getMasterDataListGrad();
        commit('setMasterDataListGrad', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataLangSkl({ commit }) {
      try {
        const res = await getMasterDataLangSkl();
        commit('setMasterDataLangSkl', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getMasterDataOtrLangSkl({ commit }) {
      try {
        const res = await getMasterOtrLang();
        commit('setMasterDataOtrLangSkl', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    // 郵便番号マスタ取得
    async getMasterDataPostCode({ commit }, {postCode}) {
      try {
        const res = await getMasterDataPostCode(postCode);
        commit('setPostcodeList',res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default masterModule;
