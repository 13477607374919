import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getApplyOrderListData } from '@/api/orderInfoEndpoint';

type ApplyOrderState = {
  orders: {
    orderId: number;
    shintyokuStatusBadgeName: string;
    empTypeName: string;
    ocupctgName: string;
    companyName: string;
  }[];
};

const module: Module<ApplyOrderState, RootState> = {
  namespaced: true,
  state: {
    orders: [],
  },
  getters: {
    total(state) {
      return state.orders.length;
    },
    orders(state) {
      return state.orders;
    },
  },

  mutations: {
    setOrders(state, value: ApplyOrderState['orders']) {
      state.orders.splice(0);
      state.orders.push(...value);
    },
  },

  actions: {
    async receive({ commit }) {
      const orders = await getApplyOrderListData();
      commit('setOrders', orders.applyOrderInfoList);
    },
  },
};

export default module;
