import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getAcademicInfo, putAcademicInfo } from '@/api/careersheetEndpoint';
import { SchoolHistoryInfoItem } from '@/types';

type careerSheetEducationState = {
  lstschlId: number | null;
  schoolHistoryInfoList: SchoolHistoryInfoItem[];
  scienceFlg: boolean;
};

const careerSheetEducationModule: Module<careerSheetEducationState, RootState> = {
  namespaced: true,
  state: {
    lstschlId: null,
    schoolHistoryInfoList: [],
    scienceFlg: false,
  },
  getters: {
    lstschlId(state) {
      return state.lstschlId;
    },
    schoolHistoryInfoList(state) {
      return state.schoolHistoryInfoList;
    },
    scienceFlg(state) {
      return state.scienceFlg;
    },
  },

  mutations: {
    set(state, value) {
      state.lstschlId = value.lstschlId;
      state.schoolHistoryInfoList = value.schoolHistoryInfoList;
      state.scienceFlg = value.scienceFlg;
    },
  },
  actions: {
    async getAcademicInfo({ commit }) {
      try {
        const res = await getAcademicInfo();
        commit('set', res);
      } catch (e) {
        console.error(e);
      }
    },
    async putAcademicInfo({ getters }) {
      try {
        const param = {
          lstschlId: getters.lstschlId,
          schoolHistoryInfoList: getters.schoolHistoryInfoList,
          scienceFlg: getters.scienceFlg,
        };
        await putAcademicInfo(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetEducationModule;
