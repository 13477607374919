import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/style.scss';
import { setupValidator } from '@/plugins/validation';
import { createGtm } from '@gtm-support/vue-gtm';

setupValidator();
createApp(App)
  .use(store)
  .use(router)
  .use(
    createGtm({
      id: 'GTM-W5WZXF',
      vueRouter: router as any,
    })
  )
  .mount('#app');
