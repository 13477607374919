// 半角→全角(英数字)
export const zenkaku2Hankaku = (str: string) => {
  return str.replace(/[A-Za-z0-9]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
  });
};

// 全角→半角(英数字)
export const hankaku2Zenkaku = (str: string) => {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
  });
};

// 数字7桁→〒xxx-xxxx(郵便番号)
export const zipCode2Jp = (str: string) => {
  return str.replace(/^([0-9]{3})([0-9]{4})/, '〒$1-$2');
};

// 電話番号にハイフンを追加する
export const telCellularAddHyphen = (str: string) => {
  return str.length === 11
    ? str.replace(/^([0-9]{3})([0-9]{4})([0-9]{4})/, '$1-$2-$3')
    : str.replace(/^([0-9]{3})([0-9]{3})([0-9]{4})/, '$1-$2-$3');
};
