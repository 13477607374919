const directoryPath = process.env.VUE_APP_DIRECTORY_PATH;

const RESUME_PATH = {
  marketing: directoryPath + '/pdf/resume_marketing.pdf', // 広報・宣伝・マーケティング
  ae: directoryPath + '/pdf/resume_AE.pdf', // 営業・プロデューサー
  creator: directoryPath + '/pdf/resume_creator.pdf', // クリエイター
  editor: directoryPath + '/pdf/resume_editor.pdf', // 編集者
};

export default RESUME_PATH;
