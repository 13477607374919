import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getWorkHistoryInfo, deleteWorkHistoryInfo, getWorkHistoryDetailInfo, putWorkHistoryDetailInfo } from '@/api/careersheetEndpoint';
import { EditingWorkHistoryDetailInfo } from '@/types';

type DisplayWorkHisInfoItem = {
  busicrId: number; // 職歴ID
  compname: string; // 企業名
  indsctgName: string; // 業種
  busicrOcupctgName: string; // 職種
  employeeNum: number | null; // 従業員数
  wkperiodeDateyr: number | null; // 期間From（年）
  wkperiodeDatemon: number | null; // 期間From（月）
  wkperiodsDateyr: number; // 期間To（年）
  wkperiodsDatemon: number; // 期間To（月）
  emptypeId: number; // 雇用形態
  post: string; // 役職
  annualincm: number; // 年収
  salary: number | null; // 月収
  wkcontents: string; // 職務内容
};

type WorkHistoryState = {
  editingBusicrId: number | null; // 編集中の職歴ID
  isMostRecent: boolean; // 編集中の職歴IDが直近か否か
  maxBusicrId: number | null; // 最大職歴ID
  displayWorkHisInfoList: DisplayWorkHisInfoItem[]; // 表示用職歴情報
  editingWorkHistoryDetailInfo: EditingWorkHistoryDetailInfo;
};

const initEditingWorkHistoryDetailInfo: EditingWorkHistoryDetailInfo = {
  busicrId: '',
  compname: '',
  indsctgId: undefined,
  employeeNum: '',
  wkperiodsDateyr: '',
  wkperiodsDatemon: '',
  wkperiodeDateyr: '',
  wkperiodeDatemon: '',
  busicrOcupctg1Id: '',
  busicrOcupctg2Id: '',
  busicrOcupctg3Id: '',
  emptypeId: undefined,
  post: '',
  annualincm: '',
  salary: '',
  wkcontents: '',
};

const workHistoryModule: Module<WorkHistoryState, RootState> = {
  namespaced: true,

  state: {
    editingBusicrId: null,
    maxBusicrId: null,
    isMostRecent: false,
    displayWorkHisInfoList: [],
    editingWorkHistoryDetailInfo: Object.assign({}, initEditingWorkHistoryDetailInfo),
  },
  getters: {
    newBusicrId(state) {
      if (state.maxBusicrId === null || state.maxBusicrId === 0) {
        return 1;
      }
      return state.maxBusicrId + 1;
    },
    editingBusicrId(state) {
      return state.editingBusicrId;
    },
    editingWorkHistoryDetailInfo(state) {
      return state.editingWorkHistoryDetailInfo;
    },
    displayWorkHisInfoList(state) {
      return state.displayWorkHisInfoList;
    },
    isMostRecent(state) {
      return state.isMostRecent;
    },
  },

  mutations: {
    setEditingBusicrId(state, value) {
      state.editingBusicrId = value;
    },
    setIsMostRecent(state, value) {
      state.isMostRecent = value;
    },
    initEditingWorkHistoryDetailInfo(state) {
      state.editingWorkHistoryDetailInfo = Object.assign({}, initEditingWorkHistoryDetailInfo);
    },
    setEditingWorkHistoryDetailInfo(state, detail: EditingWorkHistoryDetailInfo) {
      const value: EditingWorkHistoryDetailInfo = {
        busicrId: String(detail.busicrId || ''),
        compname: detail.compname,
        indsctgId: detail.indsctgId,
        employeeNum: String(detail.employeeNum === null ? '' : detail.employeeNum),
        wkperiodsDateyr: String(detail.wkperiodsDateyr || ''),
        wkperiodsDatemon: String(detail.wkperiodsDatemon || ''),
        wkperiodeDateyr: String(detail.wkperiodeDateyr || ''),
        wkperiodeDatemon: String(detail.wkperiodeDatemon || ''),
        busicrOcupctg1Id: detail.busicrOcupctg1Id,
        busicrOcupctg2Id: detail.busicrOcupctg2Id,
        busicrOcupctg3Id: detail.busicrOcupctg3Id,
        emptypeId: detail.emptypeId,
        post: String(detail.post || ''),
        annualincm: String(detail.annualincm || ''),
        salary: String(detail.salary || ''),
        wkcontents: String(detail.wkcontents || ''),
      };
      state.editingWorkHistoryDetailInfo = Object.assign({}, value);
    },
    setMaxBusicrId(state, value) {
      state.maxBusicrId = value;
    },
    setDisplayWorkHisInfoList(state, value) {
      state.displayWorkHisInfoList = value;
    },
  },
  actions: {
    async getWorkHistoryDetailInfo({ commit }, busicrId: number) {
      try {
        const res = await getWorkHistoryDetailInfo(busicrId);
        commit('setEditingWorkHistoryDetailInfo', res);
        return res;
      } catch (error) {
        console.error(error);
      }
    },
    async getWorkHistoryInfo({ commit }) {
      try {
        const res = await getWorkHistoryInfo();
        commit('setMaxBusicrId', res.maxBusicrId);
        commit('setDisplayWorkHisInfoList', res.workHisInfoList);
      } catch (error) {
        console.error(error);
      }
    },
    async deleteWorkHistoryInfo(context, busicrId: number) {
      try {
        await deleteWorkHistoryInfo({ busicrId });
      } catch (error) {
        console.error(error);
      }
    },
    async putWorkHistoryDetailInfo({ getters }) {
      const params = getters.editingWorkHistoryDetailInfo;
      try {
        await putWorkHistoryDetailInfo(params);
      } catch (error) {
        console.error(error);
      }
    },
  },
};

export default workHistoryModule;
