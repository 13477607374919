import { Module } from 'vuex';
import { RootState } from '../index';
import { getDeclineOrderListData, postApplyOrderListData } from '@/api/orderInfoEndpoint';

export type OrderCard = {
  orderId: number;
  unreadFlg: boolean;
  empTypeName: string;
  ocupctgName: string;
  catchCopy: string;
  salary: string;
  comment: string;
  companyName: string;
  location: string;
  recommendedPointFlextimeFlg: boolean;
  recommendedPointWeekendOffFlg: boolean;
  recommendedPointNoOvertimeFlg: boolean;
  recommendedPointHomeBasedFlg: boolean;
  recommendedPointOvertimeFlg: boolean;
  recommendedPointNoRelocationFlg: boolean;
  considerFlg: boolean;
  orderStatusId: number;
  // checked: boolean;
};

type DeclineOrderState = {
  page: number;
  recordsPerPage: number;
  sortKeyId: number;
  filterId: number;
  total: number;
  orders: OrderCard[];
};
const module: Module<DeclineOrderState, RootState> = {
  namespaced: true,
  state: {
    page: 1,
    recordsPerPage: 10,
    sortKeyId: 4,
    filterId: 0,
    total: 0,
    orders: [],
  },
  getters: {
    hasNextPage(state) {
      return state.page < Math.ceil(state.total / state.recordsPerPage);
    },
    total(state) {
      return state.total;
    },
    sortKeyId(state) {
      return state.sortKeyId;
    },
    filterId(state) {
      return state.filterId;
    },
    orders(state) {
      return state.orders;
    },
  },

  mutations: {
    setOrders(state, value: DeclineOrderState['orders']) {
      if (value) {
        state.orders.splice(0);
        value.forEach((order) =>
          state.orders.push({
            ...order,
          })
        );
      }
    },
    addOrders(state, value: DeclineOrderState['orders']) {
      value.forEach((order) =>
        state.orders.push({
          ...order,
        })
      );
    },
    setPage(state, value: number) {
      state.page = value;
    },
    setTotal(state, value: number) {
      state.total = value;
    },
    setSortKeyId(state, value: number) {
      state.sortKeyId = value;
    },
    setFilterId(state, value: number) {
      state.filterId = value;
    },
    removeOrder(state, { orderId }) {
      const target = state.orders.findIndex((order) => order.orderId === orderId);
      state.orders.splice(target, 1);
    },
  },

  actions: {
    async receive({ state, commit }) {
      commit('setPage', 1);
      const data = await getDeclineOrderListData(state.page, state.recordsPerPage, state.sortKeyId, state.filterId);
      commit('setOrders', data.declineOrderInfo);
      commit('setTotal', data.dataRecordCountAll);
    },
    async moreSee({ state, commit }) {
      const nextPage = state.page + 1;
      const data = await getDeclineOrderListData(nextPage, state.recordsPerPage, state.sortKeyId, state.filterId);
      if (data.declineOrderInfo) {
        commit('addOrders', data.declineOrderInfo);
        commit('setPage', nextPage);
      }
    },
    async changeSortKey({ commit, dispatch }, { key }) {
      commit('setSortKeyId', key);
      commit('setPage', 1);
      dispatch('receive');
    },
    async changeFilter({ commit, dispatch }, { filterId }) {
      commit('setFilterId', filterId);
      commit('setPage', 1);
      dispatch('receive');
    },
    async applyOrder({ commit }, { orderId }) {
      await postApplyOrderListData({ orderId, revivalApplyFlg: true });
      commit('removeOrder', { orderId });
    },
  },
};

export default module;
