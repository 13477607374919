import { Module } from 'vuex';
import { RootState } from '@/store/index';

type errorState = {
  errorState: null | number;
};

const errorModule: Module<errorState, RootState> = {
  namespaced: true,
  state: {
    errorState: null,
  },
  getters: {
    errorState(state) {
      return state.errorState;
    },
  },

  mutations: {
    setErrorState(state, value) {
      state.errorState = value;
    },
    initErrorState(state) {
      state.errorState = null;
    },
  },
  actions: {},
};

export default errorModule;
