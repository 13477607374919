import {
  deleteConsiderOrderData,
  getOrderDetailData,
  postApplyOrderListData,
  postConsiderOrderListData,
  postDeclineOrderListData,
} from '@/api/orderInfoEndpoint';
import { OfferDetail } from '@/types';
import { Module } from 'vuex';
import { RootState } from '../index';

type OfferDetailState = Partial<OfferDetail>;

const module: Module<OfferDetailState, RootState> = {
  namespaced: true,
  state: {},
  getters: {
    offerDetail(state) {
      return state;
    },
  },
  mutations: {
    set(state, value: OfferDetailState) {
      Object.assign(state, value);
    },
    reset(state) {
      for (const key of Object.keys(state)) {
        state[key as keyof OfferDetailState] = undefined;
      }
    },
    setOrderStatusId(state, value: number) {
      state.orderStatusId = value;
    },
    setConsiderFlg(state, value: boolean) {
      state.considerFlg = value;
    },
  },
  actions: {
    async receive({ commit }, { orderId }) {
      commit('reset');
      const order = await getOrderDetailData({ orderId });
      commit('set', order);
    },
    async apply({ commit, state }, { orderId }) {
      await postApplyOrderListData({
        orderId,
        revivalApplyFlg: state.orderStatusId === 3,
      });
      commit('setOrderStatusId', 2);
    },
    async decline({ commit }, { orderId }) {
      await postDeclineOrderListData({ declineOrderList: [{ orderId }] });
      commit('setOrderStatusId', 3);
    },
    async toggleConsider({ commit, state }, { orderId }) {
      if (state.considerFlg) {
        await deleteConsiderOrderData({
          orderId: orderId,
        });
      } else {
        await postConsiderOrderListData({
          considerPendingOrderList: [{ orderId }],
        });
      }
      commit('setConsiderFlg', !state.considerFlg);
    },
  },
};

export default module;
