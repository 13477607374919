import VALIDATON_ERRORS from '@/constants/validationErrors';
import { defineRule } from 'vee-validate';
import { boolean, number, string, ValidationError } from 'yup';

export const setupValidator = () => {
  defineRule('required', async (value: string | null, _: unknown, ctx) => {
    try {
      await string()
        .label(ctx.field)
        .required(VALIDATON_ERRORS.REQUIRED)
        .validate(value === null ? '' : value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('requiredAndTrue', async (value: boolean, _: unknown, ctx) => {
    try {
      await boolean().label(ctx.field).required(VALIDATON_ERRORS.REQUIRED).oneOf([true]).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('requiredBoolean', async (value: boolean, _: unknown, ctx) => {
    try {
      await boolean().nullable().label(ctx.field).required(VALIDATON_ERRORS.REQUIRED).oneOf([true, false]).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('min', async (value: string, [min]: number[], ctx) => {
    try {
      await string().label(ctx.field).min(min, VALIDATON_ERRORS.MIN_LENGTH).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('max', async (value: string, [max]: number[], ctx) => {
    try {
      await string().label(ctx.field).max(max, VALIDATON_ERRORS.MAX_LENGTH).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('betweenValue', async (value: string, [min, max]: number[], ctx) => {
    try {
      await number()
        .label(ctx.field)
        .typeError(VALIDATON_ERRORS.NUMERIC)
        .integer(VALIDATON_ERRORS.NUMERIC)
        .nullable()
        .min(min, '${label}' + `は${min}～${max}の半角数字で入力してください`)
        .max(max, '${label}' + `は${min}～${max}の半角数字で入力してください`)
        .validate(value === '' ? null : value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('numeric', async (value: string | null, _: unknown, ctx) => {
    try {
      await string()
        .label(ctx.field)
        .matches(/^\d*$/, VALIDATON_ERRORS.NUMERIC)
        .validate(value === null ? '' : value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('email', async (value: string, _: unknown, ctx) => {
    try {
      await string().label(ctx.field).email(VALIDATON_ERRORS.EMAIL).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('password', async (value: string) => {
    try {
      await string()
        .matches(/^(?=.*?[a-z])(?=.*?\d)[a-z\d]*$/i, VALIDATON_ERRORS.PASSWORD_RULE)
        .validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('confirmation', async (value: string, [target]: string[]) => {
    if (value !== target) {
      return VALIDATON_ERRORS.PASSWORD_CONFIRM;
    }
    return true;
  });

  defineRule('not_same', async (value: string, [target]: string[]) => {
    if (value === target) {
      return VALIDATON_ERRORS.PASSWORD_SAME;
    }
    return true;
  });

  defineRule('zen_kana', async (value: string) => {
    try {
      await string()
        .matches(/^[ァ-ヶー]*$/, VALIDATON_ERRORS.ZEN_KANA)
        .validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('han_eisuu_kigou', async (value: string | null, _: unknown, ctx) => {
    if (value === '') {
      return true;
    }
    try {
      await string()
        .label(ctx.field)
        .matches(/^[a-zA-Z0-9!-/:-@¥[-`{-~]+$/, VALIDATON_ERRORS.HAN_EISUU_KIGOU)
        .validate(value === null ? '' : value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('zipcode_length', async (value: string) => {
    try {
      await string().min(7, VALIDATON_ERRORS.ZIPCODE_LENGTH).validate(value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });

  defineRule('ym', async (value: string | null, _: unknown, ctx) => {
    if (value === '') {
      return true;
    }
    try {
      await string()
        .label(ctx.field)
        .matches(/^\d{4}-\d{2}$/, VALIDATON_ERRORS.YM)
        .validate(value === null ? '' : value);
    } catch (e: unknown) {
      const err = e as ValidationError;
      return err.errors[0];
    }
    return true;
  });
};
