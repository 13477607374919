import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';

type Provision = {
  consentFlg1?: boolean;
  consentFlg2?: boolean;
  consentFlg3?: boolean;
  consentFlg4?: boolean;
  lastChargeFlg?: boolean;
  aboutDateInterview?: string;
  aboutCommunicationMedia?: string;
};

export const getProvision = async (): Promise<Provision> => {
  const res = await axios.get<Provision>(endpoint.provision);
  return res.data;
};

export const putProvision = async (params: Provision) => {
  const res = await axios.put(endpoint.provision, params);
  return res;
};
