import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getMasterDataListWkStatus, MasterDataWkStatus } from '@/api/masterDataEndpoint';
import { getAdlandexp, putAdlandexp, Adlandexp, PutAdlandexp } from '@/api/careersheetEndpoint';

type ResumeCompanyState = {
  expcompNum: string; // 経験社数
  expYearNum: string; // 経験年数
  expMonthNum: string; // 経験月数
  adlandexpFlg: boolean | null; // 広告業界経験
  wkStatusId: number | null; // 就業状況
  wkStatusArray: MasterDataWkStatus[]; // 就業状況マスタ
  portfolioSiteUrl: string | null; // ポートフォリオサイトのURL
  portfolioSiteId: string | null; // ポートフォリオサイトのID
  portfolioSitePass: string | null; // ポートフォリオサイトのパスワード
};
export const initResumeCompanyState: ResumeCompanyState = {
  expcompNum: '',
  expYearNum: '',
  expMonthNum: '',
  adlandexpFlg: null,
  wkStatusId: null,
  wkStatusArray: [],
  portfolioSiteUrl: null,
  portfolioSiteId: null,
  portfolioSitePass: null,
};

const resumeCompanyModule: Module<ResumeCompanyState, RootState> = {
  namespaced: true,

  state: {
    expcompNum: '',
    expYearNum: '',
    expMonthNum: '',
    adlandexpFlg: null,
    wkStatusId: null,
    wkStatusArray: [],
    portfolioSiteUrl: null,
    portfolioSiteId: null,
    portfolioSitePass: null,
    },
  getters: {
    expcompNum(state) {
      return state.expcompNum;
    },
    expYearNum(state) {
      return state.expYearNum;
    },
    expMonthNum(state) {
      return state.expMonthNum;
    },
    adlandexpFlg(state) {
      return state.adlandexpFlg;
    },
    wkStatusId(state) {
      return state.wkStatusId;
    },
    wkStatusArray(state): MasterDataWkStatus[] {
      return state.wkStatusArray;
    },
    portfolioSiteUrl(state) {
      return state.portfolioSiteUrl;
    },
    portfolioSiteId(state) {
      return state.portfolioSiteId;
    },
    portfolioSitePass(state) {
      return state.portfolioSitePass;
    },
  },

  mutations: {
    init(state, value: Adlandexp & { wkStatusArray: MasterDataWkStatus[] }) {
      state.expcompNum = value.expcompNum === undefined ? '' : String(value.expcompNum);
      state.expYearNum = value.expYearNum === undefined ? '' : String(value.expYearNum);
      state.expMonthNum = value.expMonthNum === undefined ? '' : String(value.expMonthNum);
      state.adlandexpFlg = value.adlandexpFlg === undefined ? null : value.adlandexpFlg;
      state.wkStatusId = value.wkStatusId === undefined ? null : value.wkStatusId;
      state.wkStatusArray = value.wkStatusArray;
      state.portfolioSiteUrl = value.portfolioSiteUrl === undefined ? '' : String(value.portfolioSiteUrl);
      state.portfolioSiteId = value.portfolioSiteId === undefined ? '' : String(value.portfolioSiteId);
      state.portfolioSitePass = value.portfolioSitePass === undefined ? '' : String(value.portfolioSitePass);
    },
    set(state, value: PutAdlandexp) {
      state.expcompNum = value.expcompNum === undefined ? '' : String(value.expcompNum);
      state.expYearNum = value.expYearNum === undefined ? '' : String(value.expYearNum);
      state.expMonthNum = value.expMonthNum === undefined ? '' : String(value.expMonthNum);
      state.adlandexpFlg = value.adlandexpFlg === undefined ? null : value.adlandexpFlg;
      state.wkStatusId = value.wkStatusId === undefined ? null : value.wkStatusId;
      state.portfolioSiteUrl = value.portfolioSiteUrl === undefined ? '' : String(value.portfolioSiteUrl);
      state.portfolioSiteId = value.portfolioSiteId === undefined ? '' : String(value.portfolioSiteId);
      state.portfolioSitePass = value.portfolioSitePass === undefined ? '' : String(value.portfolioSitePass);
    },
  },
  actions: {
    async initResumeCompanyEdit({ commit }) {
      try {
        const [adlandRes, mstWkStatusRes] = await Promise.all([getAdlandexp(), getMasterDataListWkStatus()]);
        const value = {
          expcompNum: adlandRes.expcompNum,
          expYearNum: adlandRes.expYearNum,
          expMonthNum: adlandRes.expMonthNum,
          adlandexpFlg: adlandRes.adlandexpFlg,
          wkStatusId: adlandRes.wkStatusId,
          wkStatusArray: mstWkStatusRes,
          portfolioSiteUrl: adlandRes.portfolioSiteUrl,
          portfolioSiteId: adlandRes.portfolioSiteId,
          portfolioSitePass: adlandRes.portfolioSitePass,
        };
        commit('init', value);
      } catch (e) {
        console.error(e);
      } finally {
        //
      }
    },
    async updateResumeCompany({ getters }) {
      try {
        const param = {
          expcompNum: Number(getters.expcompNum),
          expYearNum: Number(getters.expYearNum),
          expMonthNum: Number(getters.expMonthNum),
          adlandexpFlg: getters.adlandexpFlg,
          wkStatusId: Number(getters.wkStatusId),
          careersheetUpdateFlg: true,
          portfolioSiteUrl: getters.portfolioSiteUrl,
          portfolioSiteId: getters.portfolioSiteId,
          portfolioSitePass: getters.portfolioSitePass,
        };
        await putAdlandexp(param);
      } catch (e) {
        console.error(e);
      } finally {
        //
      }
    },
  },
};

export default resumeCompanyModule;
