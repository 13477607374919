// entry-careerで使用を想定
import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';
import { SchoolHistoryInfoItem } from '@/types';

// todo 型定義を別ファイルにする
type AdlandexpRegist = {
  wkStatusId: number;
  expcompNum: number;
  expYearNum: number;
  expMonthNum: number;
  portfolioSiteUrl : string;
  portfolioSiteId : string;
  portfolioSitePass : string;
};
type ContactInfo = {
  telCellular: string;
  zipCode: string;
  prefId: number;
  addr1: string;
  addr2: string;
  addr3: string;
};
export type UsableLanguage = {
  langCFlg: boolean;
  cplusplusFlg: boolean;
  csharpFlg: boolean;
  javaFlg: boolean;
  phpFlg: boolean;
  sqlFlg: boolean;
  aspFlg: boolean;
  javascriptFlg: boolean;
  htmlFlg: boolean;
  xmlFlg: boolean;
  xhtmlFlg: boolean;
  cssFlg: boolean;
  sassFlg: boolean;
  ftpFlg: boolean;
  wordPressFlg: boolean;
  movableTypeFlg: boolean;
  langRFlg: boolean;
  perlFlg: boolean;
  rubyFlg: boolean;
  goFlg: boolean;
  pythonFlg: boolean;
  swiftFlg: boolean;
  kotlinFlg: boolean;
};
export type ExepInds = {
  fashionFlg: boolean;
  foodFieldFlg: boolean;
  realEstateFieldFlg: boolean;
  articlesFieldFlg: boolean;
  cosmeticsToiletryFieldFlg: boolean;
  medicalFieldFlg: boolean;
  automobileFieldFlg: boolean;
  itFieldFlg: boolean;
  electronicsFieldFlg: boolean;
  precisionEquipmentOfficeSuppliesFlg: boolean;
  retailFieldFlg: boolean;
  financingInsuranceSecuritiesFlg: boolean;
  sportsFieldFlg: boolean;
  foodServiceFieldFlg: boolean;
  beautyHealthFieldFlg: boolean;
  entertainmentFieldFlg: boolean;
  transportationSightseeingAmusementFlg: boolean;
  publicationFieldFlg: boolean;
  schoolEducationFieldFlg: boolean;
  mailOrderFieldFlg: boolean;
  governmentAdministrationFieldFlg: boolean;
  otherFieldFlg: boolean;
  otherFieldMemo: string;
};
export type UsableSoftware = {
  osWindowsFlg: boolean;
  osMacFlg: boolean;
  osUnixFlg: boolean;
  msWordFlg: boolean;
  msExcelFlg: boolean;
  msPowerpointFlg: boolean;
  msAccessFlg: boolean;
  photoshopFlg: boolean;
  illustratorFlg: boolean;
  inDesignFlg: boolean;
  dreamweaverFlg: boolean;
  adobeXDFlg: boolean;
  sketchFlg: boolean;
  figmaFlg: boolean;
  afterEffectsFlg: boolean;
  lightroomFlg: boolean;
  premiereFlg: boolean;
  finalCutProFlg: boolean;
  ediusFlg: boolean;
  unityFlg: boolean;
  threeDCgFlg: boolean;
  cadFlg: boolean;
  googleAnalyticsFlg: boolean;
  googleSearchConsoleFlg: boolean;
  biFlg: boolean;
  statisticalAnalysisFlg: boolean;
  sfaFlg: boolean;
  maFlg: boolean;
  cloudPlatFormFlg: boolean;
};
export type LangSkill = {
  engsklId: number | null;
  otrlang1Id: number | null;
  otrlang2Id: number | null;
  otrlang3Id: number | null;
};
export type Qualification = {
  qualdetails: string;
  qualyr: number;
  qualmon: number;
};
export type QualificationInfo = {
  drLicenseFlg: boolean;
  qualificationList: Qualification[];
};
export type AcademicInfo = {
  lstschlId: number | null;
  scienceFlg: boolean | null;
  schoolHistoryInfoList: SchoolHistoryInfoItem[];
};
export type DesiredConditionInfo = {
  wishIndsctg1Id: number | null;
  wishIndsctg2Id: number | null;
  wishIndsctg3Id: number | null;
  wishIndsctg4Id: number | null;
  wishIndsctg5Id: number | null;
  wishIndsctg6Id: number | null;
  wishOcupctg1Id: string | null;
  wishOcupctg2Id: string | null;
  wishOcupctg3Id: string | null;
  wishOcupctg4Id: string | null;
  wishOcupctg5Id: string | null;
  wishOcupctg6Id: string | null;
  wishWkarea1Id: number | null;
  wishWkarea2Id: number | null;
  wishWkarea3Id: number | null;
  wishWkarea4Id: number | null;
  empTypeFlg1: boolean;
  empTypeFlg5: boolean;
  empTypeFlg3: boolean;
  empTypeFlg2: boolean;
  demand: string | null;
  careersheetUpdateFlg: boolean;
  csEntryCompleteFlg: boolean;
};
export type GetWorkHisInfoList = {
  maxBusicrId: number;
  workHisInfoList: WorkHistoryInfo[];
};
export type PutWorkHisInfoList = {
  workHisInfoList: WorkHistoryDetailInfo[];
};
export type WorkHistoryInfo = {
  busicrId: number;
  compname: string;
  indsctgName: string;
  employeeNum: number | null;
  wkperiodsDateyr: number;
  wkperiodsDatemon: number;
  wkperiodeDateyr: number | null;
  wkperiodeDatemon: number | null;
  busicrOcupctgName: string;
  emptypeId: number;
  post: string | null;
  annualincm: number | null;
  salary: number | null;
  wkcontents: string | null;
};

export type WorkHistoryDetailInfo = {
  busicrId: number;
  compname: string;
  indsctgId: number;
  employeeNum: number | null;
  wkperiodsDateyr: number;
  wkperiodsDatemon: number;
  wkperiodeDateyr: number | null;
  wkperiodeDatemon: number | null;
  busicrOcupctg1Id: string | null;
  busicrOcupctg2Id: string | null;
  busicrOcupctg3Id: string | null;
  emptypeId: number;
  post: string | null;
  annualincm: number | null;
  salary: number | null;
  wkcontents: string | null;
};
type DeleteWorkHistoryInfo = {
  busicrId: number;
};
type UserBasicInfo = {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  birthDate: string;
  sexFlg: number;
  telCellular: string;
  zipCode: string;
  prefId: number;
  addr1: string;
  addr2: string;
  addr3: string;
};
type MailAddress = {
  emailAddress: string;
};
export type Adlandexp = {
  expcompNum: number;
  expYearNum: number;
  expMonthNum: number;
  adlandexpFlg: boolean;
  wkStatusId: number;
  portfolioSiteUrl : string;
  portfolioSiteId : string;
  portfolioSitePass : string;
};
export type PutAdlandexp = {
  expcompNum: number;
  expYearNum: number;
  expMonthNum: number;
  adlandexpFlg: boolean;
  wkStatusId: number;
  careersheetUpdateFlg: boolean; // キャリアシート更新を行った場合true、登録や確認の場合はfalse
  portfolioSiteUrl : string;
  portfolioSiteId : string;
  portfolioSitePass : string;
};
type PasswordEditInfo = {
  password: string;
  newPassword: string;
};

export const getContactInfo = async () => {
  const res = await axios.get(endpoint.contactInfo);
  return res.data;
};
export const postContactInfo = async (params: ContactInfo) => {
  const res = await axios.put(endpoint.contactInfo, params);
  return res;
};
export const getAdlandExpRegist = async () => {
  const res = await axios.get(endpoint.adlandexpRegist);
  return res.data;
};
export const postAdlandExpRegist = async (params: AdlandexpRegist) => {
  const res = await axios.put(endpoint.adlandexpRegist, params);
  return res;
};
export const getUsableLanguage = async (): Promise<UsableLanguage> => {
  const res = await axios.get(endpoint.usableLanguage);
  return res.data;
};
export const putUsableLanguage = async (params: UsableLanguage) => {
  const res = await axios.put(endpoint.usableLanguage, params);
  return res;
};
export const getExepInds = async (): Promise<ExepInds> => {
  const res = await axios.get(endpoint.exepInds);
  return res.data;
};
export const putExepInds = async (params: ExepInds) => {
  const res = await axios.put(endpoint.exepInds, params);
  return res;
};
export const getUsableSoftware = async (): Promise<UsableSoftware> => {
  const res = await axios.get(endpoint.usableSoftware);
  return res.data;
};
export const putUsableSoftware = async (params: UsableSoftware) => {
  const res = await axios.put(endpoint.usableSoftware, params);
  return res;
};
export const getLangSkill = async (): Promise<LangSkill> => {
  const res = await axios.get(endpoint.langSkill);
  return res.data;
};
export const putLangSkill = async (params: LangSkill) => {
  const res = await axios.put(endpoint.langSkill, params);
  return res;
};
export const getQualificationInfo = async (): Promise<QualificationInfo> => {
  const res = await axios.get(endpoint.qualificationInfo);
  return res.data;
};
export const putQualificationInfo = async (params: QualificationInfo) => {
  const res = await axios.put(endpoint.qualificationInfo, params);
  return res;
};
export const getAcademicInfo = async (): Promise<AcademicInfo> => {
  const res = await axios.get(endpoint.academicInfo);
  return res.data;
};
export const putAcademicInfo = async (params: AcademicInfo) => {
  const res = await axios.put(endpoint.academicInfo, params);
  return res;
};
// 希望条件
export const getDesiredCondition = async (): Promise<DesiredConditionInfo> => {
  const res = await axios.get(endpoint.desiredCondition);
  return res.data;
};
export const putDesiredCondition = async (params: DesiredConditionInfo) => {
  const res = await axios.put(endpoint.desiredCondition, params);
  return res;
};
// 職歴
export const getWorkHistoryInfo = async (): Promise<GetWorkHisInfoList> => {
  const res = await axios.get(endpoint.workHistoryInfo);
  return res.data;
};
export const deleteWorkHistoryInfo = async (params: DeleteWorkHistoryInfo) => {
  const res = await axios.delete(endpoint.workHistoryInfo, { data: params });
  return res.data;
};
export const putWorkHistoryInfo = async (params: PutWorkHisInfoList) => {
  const res = await axios.put(endpoint.workHistoryInfo, params);
  return res;
};
// 職歴詳細
export const getWorkHistoryDetailInfo = async (id: number): Promise<WorkHistoryDetailInfo> => {
  const res = await axios.get(`${endpoint.workHistoryDetailInfo}?busicrId=${id}`);
  return {
    ...res.data,
    busicrId: id,
  };
};
export const putWorkHistoryDetailInfo = async (params: WorkHistoryDetailInfo) => {
  const res = await axios.put(endpoint.workHistoryDetailInfo, params);
  return res.data;
};
// プロフィール基本情報
export const getUserBasicInfo = async (): Promise<UserBasicInfo> => {
  const res = await axios.get(endpoint.userBasicInfo);
  return res.data;
};
export const putUserBasicInfo = async (params: UserBasicInfo) => {
  const res = await axios.put(endpoint.userBasicInfo, params);
  return res;
};
// メールアドレス
export const getMailAddress = async (): Promise<MailAddress> => {
  const res = await axios.get(endpoint.mailAddress);
  return res.data;
};
export const putMailAddress = async (params: MailAddress) => {
  const res = await axios.put(endpoint.mailAddress, params);
  return res;
};
// 社会人経験取得
export const getAdlandexp = async (): Promise<Adlandexp> => {
  const res = await axios.get(endpoint.adlandexp);
  return res.data;
};
// 社会人経験更新
export const putAdlandexp = async (params: PutAdlandexp) => {
  const res = await axios.put(endpoint.adlandexp, params);
  return res;
};

// パスワード変更
export const postPasswordEdit = async (params: PasswordEditInfo) => {
  const res = await axios.post(endpoint.passwordEdit, params);
  return res;
};
