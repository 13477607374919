import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';

type Login = {
  emailAddress: string;
  password: string;
};

type Setting = {
  uniqueKey: string;
  password: string;
};

type Deactivate = {
  withdrawalReason: number;
  newCompany: string | undefined;
  withdrawalReasonOther: string | undefined;
  comprehensiveEvaluation: number;
  opinion: string;
};

type UserMenu = {
  firstname: string;
  lastname: string;
  displayContents: number; // 1：キャリアシート未入力、2：面談未予約、3：面談予約済み、4：面談日当日、5：利用規約未確認、6：ホーム画面
  careersheetNonInputStatus: number; // 1：連絡先、2：学歴、3：社会人経験、4：社会人経験詳細、6：希望条件、7：言語、8：PCソフト、9：プログラミング言語、10：資格、11：業界経験（5は欠番）
  mainRegTypeId: number;
  mainRegTypeName: string;
  areaName: string;
  cnslDate: string;
  interviewurl: string;
  searchOrderFlg: boolean; // 検索可能：true、検索不可能：false
};

export const postAuthLogin = async (params: Login) => {
  const res = await axios.post(endpoint.authLogin, params);
  if (!res.data.restartAccountFlg) {
    localStorage.setItem('accessToken', res.data.accessToken);
    localStorage.setItem('refreshToken', res.data.refreshToken);
  };
  return res;
};
export const postAuthLogout = async () => {
  const res = await axios.post(endpoint.authLogout);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  return res;
};

export const postPasswordReissue = async (emailAddress: string) => {
  const res = await axios.post(endpoint.passwordReissue, { emailAddress });
  return res.data;
};

export const putPasswordSetting = async (params: Setting) => {
  const res = await axios.put(endpoint.passwordSetting, params);
  return res.data;
};

export const postPasswordCheckKey = async (uniqueKey: string) => {
  const res = await axios.post(endpoint.passwordCheckKey, { uniqueKey });
  return res.data;
};

export const putDeactivate = async (params: Deactivate) => {
  await axios.put(endpoint.deactivate, params);
};

// メニュー表示制御
export const getUserMenu = async (): Promise<UserMenu> => {
  const res = await axios.get(endpoint.userMenu);
  return res.data;
};

// 90日更新 searchOrderStatus更新
export const putUpdateProvisionAndCareersheet = async () => {
  await axios.put(endpoint.updateProvisionAndCareersheet);
};

// 求職者登録ステータス更新
export const putUpdateCareerRegstatus = async () => {
  await axios.put(endpoint.updateCareerRegstatus);
};