import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getLangSkill, putLangSkill } from '@/api/careersheetEndpoint';

type careerSheetLanguageState = {
  engsklId: number | null;
  otrlang1Id: number | null;
  otrlang2Id: number | null;
  otrlang3Id: number | null;
};

const careerSheetLanguageModule: Module<careerSheetLanguageState, RootState> = {
  namespaced: true,
  state: {
    engsklId: null,
    otrlang1Id: null,
    otrlang2Id: null,
    otrlang3Id: null,
  },
  getters: {
    engsklId(state) {
      return state.engsklId;
    },
    otrlang1Id(state) {
      return state.otrlang1Id;
    },
    otrlang2Id(state) {
      return state.otrlang2Id;
    },
    otrlang3Id(state) {
      return state.otrlang3Id;
    },
  },

  mutations: {
    set(state, value) {
      state.engsklId = value.engsklId;
      state.otrlang1Id = value.otrlang1Id;
      state.otrlang2Id = value.otrlang2Id;
      state.otrlang3Id = value.otrlang3Id;
    },
  },
  actions: {
    async getLangSkill({ commit }) {
      try {
        const res = await getLangSkill();
        commit('set', res);
      } catch (e) {
        console.error(e);
      }
    },
    async putLangSkill({ getters }) {
      try {
        const param = {
          engsklId: getters.engsklId,
          otrlang1Id: getters.otrlang1Id,
          otrlang2Id: getters.otrlang2Id,
          otrlang3Id: getters.otrlang3Id,
        };
        await putLangSkill(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetLanguageModule;
