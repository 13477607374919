import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { QualificationItem, Qualifications } from '@/types';
import { getQualificationInfo, putQualificationInfo } from '@/api/careersheetEndpoint';
import { numberToDate, ymHyphenFormat, ymFormat } from '@/utils/dateFormat';

type careerSheetSkillLicenseState = {
  drLicenseFlg: boolean | null;
  qualificationList: QualificationItem[];
};

const careerSheetSkillLicenseModule: Module<careerSheetSkillLicenseState, RootState> = {
  namespaced: true,
  state: {
    drLicenseFlg: null,
    qualificationList: [
      {
        qualdetails: '',
        qualyr: 2010,
        qualmon: 1,
      },
    ],
  },
  getters: {
    param(state) {
      return {
        drLicenseFlg: state.drLicenseFlg,
        qualificationList: state.qualificationList,
      };
    },
    drLicenseFlg(state) {
      return state.drLicenseFlg;
    },
    /** 資格の編集フォームで使用できる型に変形 */
    qualifications(state): Qualifications {
      return state.qualificationList.map((i) => {
        const qualyr = i.qualyr || 2010;
        const qualmon = i.qualmon || 1;
        return {
          qualdetails: i.qualdetails || '',
          qualym: ymHyphenFormat(numberToDate(qualyr, qualmon, 1)),
        };
      });
    },
    /** 資格の確認画面で使用できる型に変形 */
    qualificationsDisplay(state) {
      return state.qualificationList.map((i) => {
        const qualyr = i.qualyr || 2010;
        const qualmon = i.qualmon || 1;
        return {
          qualdetails: i.qualdetails || '',
          qualym: ymFormat(numberToDate(qualyr, qualmon, 1)),
        };
      });
    },
  },

  mutations: {
    init(state, value) {
      Object.assign(state, value);
    },
    set(state, value) {
      const drLicenseFlg = value.drLicenseFlg;
      const qualificationList =
        value.qualifications.length > 0
          ? (value.qualifications as Qualifications).map((i) => {
              const qualym = i.qualym.split('-');
              const qualyr = Number(qualym[0]) || 2010;
              const qualmon = Number(qualym[1]) || 1;
              return {
                qualdetails: i.qualdetails,
                qualmon,
                qualyr,
              };
            })
          : [];
      state.drLicenseFlg = drLicenseFlg;
      state.qualificationList = qualificationList;
    },
  },
  actions: {
    async getQualificationInfo({ commit }) {
      try {
        const value = await getQualificationInfo();
        commit('init', value);
      } catch (e) {
        console.error(e);
      }
    },
    async putQualificationInfo({ getters }) {
      try {
        const param = getters.param;
        await putQualificationInfo(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetSkillLicenseModule;
