<template>
  <main class="is-nav">
    <div class="search_contents_wrapper">
      <div class="search_title">
        <span class="search_title_clause">現在利用できません</span>
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup></script>

<style scoped lang="scss">
// ーーーーーーーーーー
// mainの幅指定
// ーーーーーーーーーー
main {
  width: 1060px;
  margin: 0 auto;
  padding: 60px 20px;

  // SPの時
  @media screen and (max-width: variables.$breakpoint) {
    width: 100%;
  }

  // ナビゲーションがある時
  &.is-nav {
    // ヘッダー高さ：60px + ナビゲーション高さ：58px
    padding: calc(60px + 58px) 20px 60px;

    // SPの時
    @media screen and (max-width: variables.$breakpoint) {
      // スティッキーナビゲーション高さ：72px
      padding: 60px 20px calc(60px + 72px);
    }
  }
}

// ーーーーーーーーーー
// 検索を使わせない時の表示
// ーーーーーーーーーー
.search_contents_wrapper {
  width: 600px;
  margin: 70px auto 0;
  text-align: center;

  // SPの時
  @media screen and (max-width: variables.$breakpoint) {
    width: 100%;
  }
}

// 概要箇所
// ーーーーーーーーーー

.search_title {
  font-size: variables.$font_large;
  font-weight: bold;
  margin-top: 32px;
}

.search_title_clause {
  display: inline-block;
}

.search_text {
  margin-top: 24px;
}
</style>
