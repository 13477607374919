import axios from '@/plugins/axios';
import { endpoint } from './apiConstants';

type CareerId = {
    careerId: number;
};

export const getCareerId = async (): Promise<CareerId> => {
    const res = await axios.get<CareerId>(endpoint.getCareerId);
    return res.data;
};