import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getWorkHistoryDetailInfo, getWorkHistoryInfo, putWorkHistoryDetailInfo } from '@/api/careersheetEndpoint';

const initEditingWorkHistoryDetailInfo = {
  busicrId: null,
  compname: '',
  indsctgId: null,
  employeeNum: null,
  wkperiodsDateyr: null,
  wkperiodsDatemon: null,
  wkperiodeDateyr: null,
  wkperiodeDatemon: null,
  busicrOcupctg1Id: '',
  busicrOcupctg2Id: '',
  busicrOcupctg3Id: '',
  emptypeId: null,
  post: null,
  annualincm: null,
  salary: null,
  wkcontents: '',
};

export type WorkHistoryDetailInfo = {
  busicrId: number;
  compname: string;
  indsctgId: number;
  employeeNum: number | null;
  wkperiodsDateyr: number;
  wkperiodsDatemon: number;
  wkperiodeDateyr: number | null;
  wkperiodeDatemon: number | null;
  busicrOcupctg1Id: string | null;
  busicrOcupctg2Id: string | null;
  busicrOcupctg3Id: string | null;
  emptypeId: number;
  post: string | null;
  annualincm: number | null;
  salary: number | null;
  wkcontents: string | null;
};
export type NewWorkHistoryDetailInfo = {
  busicrId: number | null;
  compname: string | null;
  indsctgId: number | null;
  employeeNum: number | null;
  wkperiodsDateyr: number | null;
  wkperiodsDatemon: number | null;
  wkperiodeDateyr: number | null;
  wkperiodeDatemon: number | null;
  busicrOcupctg1Id: string | null;
  busicrOcupctg2Id: string | null;
  busicrOcupctg3Id: string | null;
  emptypeId: number | null;
  post: string | null;
  annualincm: number | null;
  salary: number | null;
  wkcontents: string | null;
};

export type EntryCareerCompany = {
  maxBusicrId: number;
  editingBusicrId: number | null; // 編集中の職歴ID
  workHistoryDetailInfo: WorkHistoryDetailInfo | NewWorkHistoryDetailInfo;
};

const entryCareerCompanyModule: Module<EntryCareerCompany, RootState> = {
  namespaced: true,

  state: {
    maxBusicrId: 1,
    editingBusicrId: 1,
    workHistoryDetailInfo: {
      busicrId: 1,
      compname: '',
      indsctgId: null,
      employeeNum: null,
      wkperiodsDateyr: null,
      wkperiodsDatemon: null,
      wkperiodeDateyr: null,
      wkperiodeDatemon: null,
      busicrOcupctg1Id: null,
      busicrOcupctg2Id: null,
      busicrOcupctg3Id: null,
      emptypeId: null,
      post: '',
      annualincm: null,
      salary: null,
      wkcontents: '',
    },
  },
  getters: {
    newBusicrId(state) {
      return state.maxBusicrId + 1;
    },
    editingBusicrId(state) {
      return state.editingBusicrId;
    },
    workHistoryDetailInfo(state) {
      return state.workHistoryDetailInfo;
    },
  },

  mutations: {
    setEditingBusicrId(state, value) {
      state.editingBusicrId = value;
    },
    setMaxBusicrId(state, value) {
      state.maxBusicrId = value;
    },
    setEditingWorkHistoryDetailInfo(state, value: WorkHistoryDetailInfo) {
      state.workHistoryDetailInfo = Object.assign(state.workHistoryDetailInfo, value);
    },
    setNewWorkHistoryDetailInfo(state) {
      state.workHistoryDetailInfo = { ...initEditingWorkHistoryDetailInfo, busicrId: state.maxBusicrId + 1 };
    },
  },
  actions: {
    async getWorkHistoryDetailInfo({ commit }, busicrId: number) {
      const res = await getWorkHistoryDetailInfo(busicrId);
      commit('setEditingWorkHistoryDetailInfo', res);
      return res;
    },
    async getWorkHistoryInfo({ commit }) {
      const res = await getWorkHistoryInfo();
      commit('setMaxBusicrId', res.maxBusicrId);
    },
    async putWorkHistoryDetailInfo({ getters }) {
      const params = getters.workHistoryDetailInfo;
      const res = await putWorkHistoryDetailInfo(params);
      return res;
    },
  },
};

export default entryCareerCompanyModule;
