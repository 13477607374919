import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { getUsableSoftware, putUsableSoftware, UsableSoftware } from '@/api/careersheetEndpoint';

type careerSheetSkillState = {
  usableSoftwareList: { label: string; value: boolean; key: string }[];
};

const careerSheetSkillModule: Module<careerSheetSkillState, RootState> = {
  namespaced: true,
  state: {
    usableSoftwareList: [],
  },
  getters: {
    usableSoftwareList(state) {
      return state.usableSoftwareList;
    },
    usableSoftwareListParam(state): { [key: string]: boolean } {
      return Object.fromEntries(
        state.usableSoftwareList.map((i) => {
          return [i.key, i.value];
        })
      );
    },
  },

  mutations: {
    setUsableSoftwareList(state, value) {
      state.usableSoftwareList = value.map((i: { label: string; value: boolean; key: string }) => {
        return {
          ...i,
          value: i.value || false,
        };
      });
    },
  },
  actions: {
    async getUsableSoftwareList({ commit }) {
      try {
        const res = await getUsableSoftware();
        const value = [
          { label: 'Windows', value: res.osWindowsFlg, key: 'osWindowsFlg' },
          { label: 'Mac', value: res.osMacFlg, key: 'osMacFlg' },
          { label: 'Unix', value: res.osUnixFlg, key: 'osUnixFlg' },
          { label: 'Microsoft Word', value: res.msWordFlg, key: 'msWordFlg' },
          { label: 'Microsoft Excel', value: res.msExcelFlg, key: 'msExcelFlg' },
          { label: 'Microsoft Powerpoint', value: res.msPowerpointFlg, key: 'msPowerpointFlg' },
          { label: 'Microsoft Access', value: res.msAccessFlg, key: 'msAccessFlg' },
          { label: 'Photoshop', value: res.photoshopFlg, key: 'photoshopFlg' },
          { label: 'Illustrator', value: res.illustratorFlg, key: 'illustratorFlg' },
          { label: 'InDesign', value: res.inDesignFlg, key: 'inDesignFlg' },
          { label: 'Dreamweaver', value: res.dreamweaverFlg, key: 'dreamweaverFlg' },
          { label: 'AdobeXD', value: res.adobeXDFlg, key: 'adobeXDFlg' },
          { label: 'Sketch', value: res.sketchFlg, key: 'sketchFlg' },
          { label: 'Figma', value: res.figmaFlg, key: 'figmaFlg' },
          { label: 'AfterEffects', value: res.afterEffectsFlg, key: 'afterEffectsFlg' },
          { label: 'Lightroom', value: res.lightroomFlg, key: 'lightroomFlg' },
          { label: 'Premiere', value: res.premiereFlg, key: 'premiereFlg' },
          { label: 'FinalCutPro', value: res.finalCutProFlg, key: 'finalCutProFlg' },
          { label: 'EDIUS', value: res.ediusFlg, key: 'ediusFlg' },
          { label: 'Unity', value: res.unityFlg, key: 'unityFlg' },
          { label: '3DCG（3ds Max、Maya、STRATAなど）', value: res.threeDCgFlg, key: 'threeDCgFlg' },
          { label: 'CAD（shade、Vectorworksなど）', value: res.cadFlg, key: 'cadFlg' },
          { label: 'Google Analytics', value: res.googleAnalyticsFlg, key: 'googleAnalyticsFlg' },
          { label: 'Google Search Console', value: res.googleSearchConsoleFlg, key: 'googleSearchConsoleFlg' },
          { label: 'BIツール（Tableau、QlikViewなど）', value: res.biFlg, key: 'biFlg' },
          { label: '統計解析ツール（SPSS、SAS、JMPなど）', value: res.statisticalAnalysisFlg, key: 'statisticalAnalysisFlg' },
          { label: 'SFAツール（Salesforceなど）', value: res.sfaFlg, key: 'sfaFlg' },
          { label: 'MAツール（Pardot、Marketoなど）', value: res.maFlg, key: 'maFlg' },
          { label: 'クラウドプラットフォーム（AWS、GCP、Azureなど）', value: res.cloudPlatFormFlg, key: 'cloudPlatFormFlg' },
        ];
        commit('setUsableSoftwareList', value);
      } catch (e) {
        console.error(e);
      }
    },
    async saveUsableSoftwareList({ getters }) {
      try {
        const param = getters.usableSoftwareListParam as UsableSoftware;
        await putUsableSoftware(param);
      } catch (e) {
        console.error(e);
      }
    },
  },
};

export default careerSheetSkillModule;
