import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { putInterviewReserveInterview, getInterviewInfo, InterviewInfoResponse, cancelInterview } from '@/api/interviewEndpoint';
import { MasterDataMainRegTypeCareer } from '@/api/masterDataEndpoint';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';

type AppointmentState = {
  reservationId: number | null;
  mainRegTypeId: string;
  mainRegTypeName: string;
  mainRegTypeOption: MasterDataMainRegTypeCareer[];
  areaId: string;
  areaName: string;
  interviewDate: string;
  interviewDateDisplay: string;
  interviewTime: string;
  interviewTimeDisplay: string;
  changeInterviewFlg: boolean;
};
export const initAppointmentState: AppointmentState = {
  reservationId: null,
  mainRegTypeId: '',
  mainRegTypeName: '',
  mainRegTypeOption: [],
  areaId: '',
  areaName: '',
  interviewDate: '',
  interviewDateDisplay: '',
  interviewTime: '',
  interviewTimeDisplay: '',
  changeInterviewFlg: true,
};

const appointmentModule: Module<AppointmentState, RootState> = {
  namespaced: true,

  state: {
    reservationId: null,
    mainRegTypeId: '',
    mainRegTypeName: '',
    mainRegTypeOption: [],
    areaId: '',
    areaName: '',
    interviewDate: '',
    interviewDateDisplay: '',
    interviewTime: '',
    interviewTimeDisplay: '',
    changeInterviewFlg: true,
  },
  getters: {
    changeInterviewFlg(state) {
      return state.changeInterviewFlg;
    },
    reservationId(state) {
      return state.reservationId;
    },
    mainRegTypeId(state) {
      return state.mainRegTypeId;
    },
    mainRegTypeName(state) {
      return state.mainRegTypeName;
    },
    mainRegTypeOption(state): MasterDataMainRegTypeCareer[] {
      return state.mainRegTypeOption;
    },
    areaId(state) {
      return state.areaId;
    },
    areaName(state) {
      return state.areaName;
    },
    selectedDate(state) {
      if (state.interviewDate.length === 8) {
        return state.interviewDate.replace(/^([0-9]{4})([0-9]{2})([0-9]{2})/, '$1-$2-$3');
      }
      return '';
    },
    jpInterviewDate(state, getters) {
      if (getters.selectedDate === '') {
        return '';
      }
      return format(new Date(getters.selectedDate), 'yyyy年M月d日（E）', { locale: ja });
    },
    interviewDateDisplay(state) {
      return state.interviewDateDisplay;
    },
    interviewTime(state) {
      return state.interviewTime;
    },
    interviewTimeLabel(state) {
      if (state.interviewTime.length < 3) {
        return '';
      }
      if (state.interviewTime.length === 3) {
        return state.interviewTime.replace(/^([0-9]{1})([0-9]{2})/, '$1:$2〜');
      }
      return state.interviewTime.replace(/^([0-9]{2})([0-9]{2})/, '$1:$2〜');
    },
    interviewTimeDisplay(state) {
      return state.interviewTimeDisplay;
    },
  },

  mutations: {
    setMainRegTypeId(state, value: string) {
      state.mainRegTypeId = value;
    },
    setMainRegTypeOption(state, value: MasterDataMainRegTypeCareer[]) {
      state.mainRegTypeOption = value;
    },
    setAreaId(state, value: string) {
      state.areaId = value;
    },
    setInterviewDate(state, value: string) {
      state.interviewDate = value;
    },
    setInterviewTime(state, value: string) {
      state.interviewTime = value;
    },
    setInfo(state, value: InterviewInfoResponse) {
      state.areaId = String(value.areaId);
      state.areaName = value.areaName;
      state.interviewDate = value.interviewDate.replace(/-/g, '');
      state.interviewDateDisplay = value.interviewDateDisplay;
      state.interviewTime = value.interviewTime;
      state.interviewTimeDisplay = value.interviewTimeDisplay;
      state.mainRegTypeId = String(value.mainRegTypeId);
      state.mainRegTypeName = value.mainRegTypeName;
      state.reservationId = value.reservationId;
      state.changeInterviewFlg = value.changeInterviewFlg;
    },
    init(state) {
      state.reservationId = initAppointmentState.reservationId;
      state.mainRegTypeId = initAppointmentState.mainRegTypeId;
      state.mainRegTypeName = initAppointmentState.mainRegTypeName;
      state.mainRegTypeOption = initAppointmentState.mainRegTypeOption;
      state.areaId = initAppointmentState.areaId;
      state.areaName = initAppointmentState.areaName;
      state.interviewDate = initAppointmentState.interviewDate;
      state.interviewDateDisplay = initAppointmentState.interviewDateDisplay;
      state.interviewTime = initAppointmentState.interviewTime;
      state.interviewTimeDisplay = initAppointmentState.interviewTimeDisplay;
      state.changeInterviewFlg = initAppointmentState.changeInterviewFlg;
    },
  },
  actions: {
    async reserveInterview({ state, getters }) {
      const request = {
        areaId: Number(state.areaId),
        interviewDate: getters.selectedDate,
        interviewTime: state.interviewTime,
        mainRegTypeId: Number(state.mainRegTypeId),
        reservationId: state.reservationId,
      };
      const res = await putInterviewReserveInterview(request);
      return res;
    },
    async getInterviewInfo({ commit }) {
      const res = await getInterviewInfo();
      if (res.reservationId === null) {
        commit('init');
        return res;
      }
      commit('setInfo', res);
      return res;
    },
    async cancelInterview({ commit }) {
      const res = await cancelInterview();
      commit('init');
      return res;
    },
  },
};

export default appointmentModule;
