import { Module } from 'vuex';
import { RootState } from '@/store/index';
import { RouteLocationNormalizedLoaded, RouteRecordName, RouteParams, LocationQuery } from 'vue-router';

type routerState = {
  loginTransitionPageName: RouteRecordName | undefined | null;
  loginTransitionPageParams: RouteParams;
  loginTransitionPageQuery: LocationQuery;
};

const homeName = 'f00';

const routerModule: Module<routerState, RootState> = {
  namespaced: true,
  state: {
    loginTransitionPageName: homeName, // ログインで遷移するページ名
    loginTransitionPageParams: {},
    loginTransitionPageQuery: {},
  },
  getters: {
    loginTransitionPageData(state) {
      return {
        name: state.loginTransitionPageName,
        params: state.loginTransitionPageParams,
        query: state.loginTransitionPageQuery,
      };
    },
  },

  mutations: {
    setLoginTransitionData(state, route: RouteLocationNormalizedLoaded) {
      state.loginTransitionPageName = route.name;
      state.loginTransitionPageParams = route.params;
      state.loginTransitionPageQuery = route.query;
    },
    initLoginTransitionData(state) {
      state.loginTransitionPageName = homeName;
      state.loginTransitionPageParams = {};
      state.loginTransitionPageQuery = {};
    },
  },

  actions: {},
};

export default routerModule;
