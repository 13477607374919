import { createStore, StoreOptions } from 'vuex';

import toatsModule from '@/store/modules/toasts';
import userRegistrationModule from '@/store/modules/userRegistration';
import provisionModule from '@/store/modules/provision';
import appointmentModule from '@/store/modules/appointment';
import applyOrderModule from '@/store/modules/applyOrder';
import recommendOrderModule from '@/store/modules/recommendOrder';
import mylistOrderModule from '@/store/modules/mylistOrder';
import declineOrderModule from '@/store/modules/declineOrder';

import entryCareerCompanyModule from '@/store/modules/entryCareerCompany';
import userMenuModule from '@/store/modules/userMenu';
import resumeCompanyModule from '@/store/modules/resumeCompany';
import workHistoryModule from '@/store/modules/workHistory';
import searchOrderModule from '@/store/modules/searchOrder';
import masterModule from '@/store/modules/master';
import careerSheetBusinesstypeModule from '@/store/modules/careerSheetBusinesstype';
import careerSheetSkillModule from '@/store/modules/careerSheetSkill';
import careerSheetPclanguageModule from '@/store/modules/careerSheetPclanguage';
import careerSheetDesiredModule from '@/store/modules/careerSheetDesired';
import offerDetailModule from '@/store/modules/offerDetail';
import careerSheetEducationModule from '@/store/modules/careerSheetEducation';
import careerSheetLanguageModule from '@/store/modules/careerSheetLanguage';
import careerSheetSkillLicenseModule from '@/store/modules/careerSheetSkillLicense';
import errorModule from '@/store/modules/error';
import routerModule from '@/store/modules/router';
import careerIdModule from '@/store/modules/careerId';

export type RootState = {
  version: string;
};

const store: StoreOptions<RootState> = {
  modules: {
    toasts: toatsModule,
    userMenu: userMenuModule,
    userRegistration: userRegistrationModule,
    provision: provisionModule,
    appointment: appointmentModule,
    entryCareerCompany: entryCareerCompanyModule,
    resumeCompany: resumeCompanyModule,
    workHistory: workHistoryModule,
    master: masterModule,
    applyOrder: applyOrderModule,
    recommendOrder: recommendOrderModule,
    mylistOrder: mylistOrderModule,
    declineOrder: declineOrderModule,
    searchOrder: searchOrderModule,
    careerSheetBusinesstype: careerSheetBusinesstypeModule,
    careerSheetSkill: careerSheetSkillModule,
    careerSheetPclanguage: careerSheetPclanguageModule,
    careerSheetDesired: careerSheetDesiredModule,
    offerDetail: offerDetailModule,
    careerSheetEducation: careerSheetEducationModule,
    careerSheetLanguage: careerSheetLanguageModule,
    careerSheetSkillLicense: careerSheetSkillLicenseModule,
    error: errorModule,
    router: routerModule,
    careerId: careerIdModule,
  },
};

export default createStore<RootState>(store);
