// 面談予約で使用を想定
import axios from '@/plugins/axios';
import { endpoint } from '@/api/apiConstants';

export type InterviewDateParam = {
  areaId: string;
  interviewMonth: string; // 'yyyyMM'
};

export type InterviewDateResponse = {
  interviewTimeList: {
    interviewTime: string;
    interviewTimeStatus: boolean;
  }[];
};

export type InterviewTimeParam = {
  areaId: string;
  interviewDate: string; // 'yyyyMMdd'
};

export type InterviewTimeResponse = {
  interviewTimeList: {
    interviewTimeId: string;
    interviewTimeName: string;
  }[];
};

export type ReserveInterviewParam = {
  areaId: number;
  interviewDate: string;
  interviewTime: string;
  mainRegTypeId: number;
  reservationId: number | null; // 新規登録はnull
};

export type InterviewInfoResponse = {
  areaId: number;
  areaName: string; // 東京
  interviewDate: string; // yyyy-MM-dd
  interviewDateDisplay: string; // 2022年11月23日(水)
  interviewTime: string; // HH:mm
  interviewTimeDisplay: string; // 11:00～
  mainRegTypeId: number;
  mainRegTypeName: string; // 電話
  reservationId: number;
  changeInterviewFlg: boolean;
};

export const getInterviewDate = async (params: InterviewDateParam): Promise<InterviewDateResponse> => {
  if (params.areaId === undefined) return { interviewTimeList: [] };
  const res = await axios.get(endpoint.interviewDate, { params });
  return res.data;
};

export const getInterviewTime = async (params: InterviewTimeParam): Promise<InterviewTimeResponse> => {
  if (params.areaId === undefined) return { interviewTimeList: [] };
  const res = await axios.get(endpoint.interviewTime, { params });
  return res.data;
};

export const putInterviewReserveInterview = async (request: ReserveInterviewParam) => {
  const res = await axios.put(endpoint.reserveInterview, request);
  return res;
};

export const getInterviewInfo = async (): Promise<InterviewInfoResponse> => {
  const res = await axios.get(endpoint.getInterviewInfo);
  return res.data;
};

export const cancelInterview = async () => {
  const res = axios.delete(endpoint.cancelInterview);
  return res;
};
